import {
  THEME_ATMORE,
  THEME_MONTGOMERY,
  THEME_WCC,
  THEME_WETUMPKA,
  THEME_BETHLEHEM,
  THEME_ARUBA,
  THEME_CURACAO,
  THEME_WASHESHU,
} from 'themes';

export const PROPERTY_ATMORE = 'WCA';
export const PROPERTY_BETHLEHEM = 'WCB';
export const PROPERTY_MONTGOMERY = 'WCM';
export const PROPERTY_WETUMPKA = 'WCW';
export const PROPERTY_ONLINE = 'ONLINE';
export const PROPERTY_WASHESHU = 'WASHESHU';
export const PROPERTY_ARUBA = 'ARUBA';
export const PROPERTY_CURACAO = 'CURACAO';

export const DEFAULT_PROPERTY = PROPERTY_ATMORE;

export const THEME_PROPERTY_MAP = {
  [THEME_WCC]: DEFAULT_PROPERTY,
  [THEME_ATMORE]: PROPERTY_ATMORE,
  [THEME_BETHLEHEM]: PROPERTY_BETHLEHEM,
  [THEME_MONTGOMERY]: PROPERTY_MONTGOMERY,
  [THEME_WETUMPKA]: PROPERTY_WETUMPKA,
  [THEME_ARUBA]: PROPERTY_ARUBA,
  [THEME_CURACAO]: PROPERTY_CURACAO,
  [THEME_WASHESHU]: PROPERTY_WASHESHU,
};

export const PROPERTY_TIME_ZONE = {
  [PROPERTY_ATMORE]: 'America/Chicago',
  [PROPERTY_BETHLEHEM]: 'America/New_York',
  [PROPERTY_MONTGOMERY]: 'America/Chicago',
  [PROPERTY_WETUMPKA]: 'America/Chicago',
  [PROPERTY_ONLINE]: 'America/Chicago',
  [PROPERTY_ARUBA]: 'America/Aruba',
  [PROPERTY_CURACAO]: 'America/Curacao',
  [PROPERTY_WASHESHU]: 'America/Los_Angeles',
};

// Event types
export const PROMOTION_EVENT = 'promotions';
export const SPECIAL_EVENT = 'special';
export const ENTERTAINMENT_EVENT = 'entertainment';
export const EVENT_TYPES = [
  PROMOTION_EVENT,
  SPECIAL_EVENT,
  ENTERTAINMENT_EVENT,
];
export const EVENT_TYPE_DISPLAY_NAMES = {
  [PROMOTION_EVENT]: 'Promotions',
  [SPECIAL_EVENT]: 'Specials',
  [ENTERTAINMENT_EVENT]: 'Entertainment',
};
export const MAX_EVENTS_SHOWN = 3;

// Promotion types
export const BONUS_GAME = 'BONUS_GAME';
export const EARN_GET = 'EARN_GET';
export const ELECTRONIC_DRAWING = 'ELECTRONIC_DRAWING';
export const FOOD_CREDIT = 'FOOD_CREDIT';
export const HOT_SEATS = 'HOT_SEATS';
export const TICKET_DRAWING = 'TICKET_DRAWING';
export const HOLIDAY_SHOPPING_DOLLARS = 'HOLIDAY_SHOPPING_DOLLARS';
export const COME_AND_GET = 'COME_AND_GET';
export const FREE_PLAY = 'FREE_PLAY';
export const SPA_SPECIAL = 'SPA_SPECIAL';
export const TOURNAMENT = 'TOURNAMENT';
export const ONLINE_TOURNAMENT = 'ONLINE_TOURNAMENT';
export const ONLINE_DRAWING = 'ONLINE_DRAWING';

// Recurring types
export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';
export const ANNUALLY = 'annually';

// Tab constants
export const CALENDAR_TAB = 'CALENDAR_TAB';
export const LIST_TAB = 'LIST_TAB';

export const MONDAY = 'Monday';
export const TUESDAY = 'Tuesday';
export const WEDNESDAY = 'Wednesday';
export const THURSDAY = 'Thursday';
export const FRIDAY = 'Friday';
export const SATURDAY = 'Saturday';
export const SUNDAY = 'Sunday';

export const DAYS_OF_WEEK = {
  1: MONDAY,
  2: TUESDAY,
  3: WEDNESDAY,
  4: THURSDAY,
  5: FRIDAY,
  6: SATURDAY,
  7: SUNDAY,
};

// app view modes
export const MINI_CALENDAR = 'MINI_CALENDAR';
export const FULL_VIEW = 'FULL_VIEW';
export const UPCOMING_EVENTS = 'UPCOMING_EVENTS';
export const UPCOMING_EVENTS_WITH_FEATURED = 'UPCOMING_EVENTS_WITH_FEATURED';
export const UPCOMING_EVENTS_WITH_CALENDAR = 'UPCOMING_EVENTS_WITH_CALENDAR';
export const FEATURED_EVENT = 'FEATURED_EVENT';
export const ENTERTAINMENT_CALENDAR = 'ENTERTAINMENT_CALENDAR';
export const ENTERTAINMENT_CALENDAR_WITH_DETAILS =
  'ENTERTAINMENT_CALENDAR_WITH_DETAILS';

export const DEFAULT_VIEW_MODE = FULL_VIEW;

// Calendar types
export const DINING = 'DINING';
export const LIVE_ENTERTAINMENT = 'LIVE_ENTERTAINMENT';
export const NIGHTLIFE = 'NIGHTLIFE';
export const PROMOTIONS = 'PROMOTIONS';

// Promo Perks
// Any new types also need to be inserted into the promo_perks table,
// and the admin should be updated with the new icons.
export const PRIZE_MULTIPLIER = 'PRIZE_MULTIPLIER';
export const AUTO_ADVANCE = 'AUTO_ADVANCE';
export const AUTO_ADVANCE_PLUS = 'AUTO_ADVANCE_PLUS';
export const AUTO_WIN = 'AUTO_WIN';
export const PRIZE_BOOSTER = 'PRIZE_BOOSTER';
export const PRIZE_DOUBLER = 'PRIZE_DOUBLER';
export const PICK_FIRST = 'PICK_FIRST';
export const VIRTUAL_WIN = 'VIRTUAL_WIN';
export const WIN_FROM_HOME = 'WIN_FROM_HOME';
export const ADD_A_FRIEND = 'ADD_A_FRIEND';
export const MYSTERY_BOX = 'MYSTERY_BOX';
export const CASH_IS_KING = 'CASH_IS_KING';
export const EXTRA_ENTRIES = 'EXTRA_ENTRIES';
export const EXTRA_EXTRA = 'EXTRA_EXTRA';
export const FREE_ENTRY = 'FREE_ENTRY';
export const DECKED_OUT = 'DECKED_OUT';
export const ALL_MINE = 'ALL_MINE';
export const FREE_FOR_ME = 'FREE_FOR_ME';
export const TRIGGERED = 'TRIGGERED';
export const FREE_FOOD_FRENZY = 'FREE_FOOD_FRENZY';
export const WATERFALL = 'WATERFALL';
export const SHARE_THE_WIN = 'SHARE_THE_WIN';
export const OH_SNAP = 'OH_SNAP';
export const JETSETTER = 'JETSETTER';
export const STAND_BY = 'STAND_BY';
export const BOUNCEBACK = 'BOUNCEBACK';
export const DO_OVER = 'DO_OVER';
export const FREE_SPIN = 'FREE_SPIN';
export const CALL_MY_NAME = 'CALL_MY_NAME';

// Verse Perks
// Any new types also need to be inserted into the verse_perks table,
// and the admin should be updated with the new icons.
export const VERSE_PERKS_AUTO_SPIN = 'AUTO_SPIN';
export const VERSE_PERKS_AUTO_WIN = 'AUTO_WIN';
export const VERSE_PERKS_CALL_MY_NAME = 'CALL_MY_NAME';
export const VERSE_PERKS_CURRENCY_EXCHANGE = 'CURRENCY_EXCHANGE';
export const VERSE_PERKS_DOUBLEU_SCORE = 'DOUBLEU_SCORE';
export const VERSE_PERKS_FREE_SPIN = 'FREE_SPIN';
export const VERSE_PERKS_MAGNET = 'MAGNET';
export const VERSE_PERKS_PRIZE_DOUBLER = 'PRIZE_DOUBLER';
export const VERSE_PERKS_REPEAT_CHALLENGE = 'REPEAT_CHALLENGE';
export const VERSE_PERKS_RESPIN_WHEEL = 'RESPIN_WHEEL';
export const VERSE_PERKS_TIME_WARP = 'TIME_WARP';
export const VERSE_PERKS_WMORE = 'WMORE';

// Venues
export const AMPHITHEATER = 'AMPHITHEATER';
export const SOUND = 'SOUND';
export const CENTER_BAR_ATMORE = 'CENTER_BAR_ATMORE';
export const ENTERTAINMENT_CENTER = 'ENTERTAINMENT_CENTER';
export const CENTER_BAR_WETUMPKA = 'CENTER_BAR_WETUMPKA';
export const COIL = 'COIL';
export const MOLTEN_LOUNGE = 'MOLTEN_LOUNGE';
export const WIND_CREEK_EVENT_CENTER = 'WIND_CREEK_EVENT_CENTER';
export const VISION_BAR = 'VISION_BAR';
export const BB_KING = 'BB_KING';
export const ITTA_BENA = 'ITTA_BENA';
export const CULINARY_STUDIO = 'CULINARY_STUDIO';
export const MIXX = 'MIXX';

export const VENUE_NAME = {
  [AMPHITHEATER]: 'Amphitheater',
  [SOUND]: 'Sound',
  [CENTER_BAR_ATMORE]: 'Center Bar',
  [ENTERTAINMENT_CENTER]: 'Entertainment Center',
  [CENTER_BAR_WETUMPKA]: 'Center Bar',
  [COIL]: 'Coil',
  [MOLTEN_LOUNGE]: 'Molten Lounge',
  [WIND_CREEK_EVENT_CENTER]: 'Wind Creek Event Center',
  [VISION_BAR]: 'Vision Bar',
  [BB_KING]: "B. B. King's Blues Club",
  [ITTA_BENA]: 'Itta Bena',
  [CULINARY_STUDIO]: 'Culinary Studio',
  [MIXX]: 'Mixx',
};
