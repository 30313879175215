import merge from 'lodash/merge';
import { lighten, rgba } from 'polished';

export const THEME_WCC = 'wcc';
export const THEME_WCC_RESPONSIVE = 'wcc_responsive';
export const THEME_ATMORE = 'atmore';
export const THEME_BETHLEHEM = 'bethlehem';
export const THEME_WETUMPKA = 'wetumpka';
export const THEME_MONTGOMERY = 'montgomery';
export const THEME_ARUBA = 'aruba';
export const THEME_CURACAO = 'curacao';
export const THEME_WASHESHU = 'washeshu';

export const DEFAULT_THEME = THEME_WCC;

const colors = {
  white: '#fff',
  purple: '#ba5cd6',
  darkPurple: '#4a0a9b',
  lightBlue: '#7da3fe',
  blue: '#283278',
  darkBlue: '#122038',
  teal: '#0b4f6c',
  lightTeal: '#c9e7ea',
  red: '#ff0000',
  green: '#1cb874',
  black: '#000',
  grey: '#eee',
  darkGrey: '#c6c6c6',
  scorpionGrey: '#5c5c5c',
  darkerGrey: '#444444',
  borderGrey: '#636466',
  inputBackgroundGrey: '#f4f4f4',
  appBackgroundGrey: '#fbfbfb',
  hoverGrey: '#e7e7e7',
  lightGrey: '#f2f2f2',
  lighterGrey: '#dbdbdb',
  textGrey: '#636466',
  gold: '#f1ba00',
  navy: '#1a2339',
  hotPink: '#ed004b',
  subHeaderRed: '#74121d',
  atmoreRed: '#a32b29',
  atmoreLightRed: '#de3636',
  atmoreLighterRed: '#d19594',
  atmoreMediumRed: '#8f2624',
  atmoreDarkRed: '#811b1b',
  atmoreDarkestRed: '#4e0b0b',
  montgomeryLightBlue: '#7da3fe',
  montgomeryBlue: '#283278',
  montgomeryDarkBlue: '#192055',
  montgomeryDarkestBlue: '#0e122e',
  wetumpkaLightOrange: '#ee7522',
  wetumpkaOrange: '#ca4719',
  wetumpkaDarkOrange: '#a03512',
  wetumpkaDarkestOrange: '#782509',
  bethlehemRed: '#a32b29',
  bethlehemLightRed: '#de3636',
  bethlehemDarkRed: '#811b1b',
  bethlehemDarkestRed: '#4e0b0b',
  arubaLightBlue: '#7da3fe',
  arubaBlue: '#218dc2',
  arubaDarkBlue: '#192055',
  arubaDarkestBlue: '#0e122e',
  curacaoLight: '#ee7522',
  curacaoPrimary: '#f3b121',
  curacaoDark: '#a03512',
  curacaoDarkest: '#782509',
  washeshuLight: '#ee7522',
  washeshuPrimary: '#ef483e',
  washeshuDark: '#a03512',
  washeshuDarkest: '#782509',
  lightCyanBlue: '#dee2e6',
  disabledInput: '#e9ecef',
  entertainmentEvent: '#ff002d',
  promotionEvent: '#00c177',
  specialEvent: '#16aad9',
  lightYellow: '#fadda7',
  lightRed: '#f8d6d3',
};

const breakpoints = {
  extraSmall: '400px',
  small: '576px',
  medium: '768px',
  large: '928px',
  extraLarge: '1200px',
  extraExtraLarge: '1499px',
};

const themeDefaults = {
  name: DEFAULT_THEME,
  fullWidth: '100%',
  appHeight: '39.2857rem',
  fontFamily: 'proxima-nova',
  fontBold: 'bold',
  fontColor: colors.textGrey,
  textUpperCase: 'uppercase',
  center: '0 auto',
  colors: colors,
  breakpoints: breakpoints,
  calendar: {
    fontColor: colors.darkerGrey,
    tile: {
      startEndBackgroundColor: colors.teal,
      activeBackgroundColor: colors.teal,
      rateFontColor: colors.atmoreRed,
      discountedRateFontColor: colors.teal,
      dayFontColor: colors.darkerGrey,
      disabled: {
        backgroundColor: colors.lightGrey,
        dayFontColor: colors.darkGrey,
        neighboringMonth: {
          backgroundColor: colors.lightGrey,
          dayFontColor: colors.darkGrey,
        },
      },
    },
    navigation: {
      backgroundColor: colors.atmoreRed,
    },
    minHeight: '550px',
  },
  buttons: {
    fontFamily:
      '"Knockout 48 A", "Knockout 48 B", "Arial Narrow", Arial, sans-serif',
    fontStyle: 'normal',
    fontSize: '1.3125rem',
    border: '0.125rem solid',
    borderRadius: '1.75rem',
    cursor: 'pointer',
    display: 'inline-block',
    fontWeight: 500,
    letterSpacing: '0.1875rem',
    maxWidth: '100%',
    padding: '.5625rem 0',
    textAlign: 'center',
    textTransform: 'uppercase',
    textDecoration: 'none',
    transition: ' all .15s ease 0s',
    whiteSpace: 'nowrap',
    width: '15.63rem',

    primary: {
      backgroundColor: colors.atmoreRed,
      borderColor: colors.atmoreRed,
      color: colors.white,
      focusHover: {
        backgroundColor: colors.atmoreMediumRed,
      },
    },
    white: {
      backgroundColor: colors.white,
      borderColor: colors.white,
      color: colors.atmoreRed,
      transition: 'background-color,box-shadow,color,border',
      transitionDuration: '.15s',
      transitionTimingFunction: 'ease-in',
      focusHover: {
        backgroundColor: colors.lightGrey,
        borderColor: colors.atmoreRed,
        color: colors.atmoreRed,
      },
    },
    outline: {
      backgroundColor: colors.white,
      borderColor: colors.atmoreRed,
      color: colors.atmoreRed,
      transition: 'background-color,box-shadow,color',
      transitionDuration: '.15s',
      transitionTimingFunction: 'ease-in',
      focusHover: {
        backgroundColor: colors.atmoreRed,
        borderColor: colors.atmoreRed,
        color: colors.white,
      },
    },
    small: {
      width: '10.44rem',
    },
    iconOnly: {
      height: '3rem',
      width: '3rem',
      padding: '0.25rem',
    },
    disabled: {
      cursor: 'default',
      opacity: '.5',
      focusHover: {
        boxShadow: 'none',
      },
    },
    text: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderRadius: 0,
      color: colors.atmoreRed,
      padding: '.5625rem 0',
      textDecoration: 'underline',
      width: 'auto',
      focusHover: {
        textDecoration: 'none',
      },
    },
    trigger: {
      fontSize: '1.5rem',
      backgroundColor: colors.inputBackgroundGrey,
      borderColor: 'transparent',
      borderRadius: '0.25rem',
      color: colors.darkerGrey,
      height: '2.5rem',
      letterSpacing: '0.140625rem',
      lineHeight: 1.5,
      padding: '0 0.3125rem',
      textAlign: 'left',
      width: 'auto',
      focusHover: {
        backgroundColor: colors.hoverGrey,
      },
      iconColor: colors.atmoreRed,
    },
    /* TODO: consider removing this */
    secondary: {
      backgroundColor: colors.atmoreDarkRed,
    },
    color: colors.white,
    borderColor: colors.atmoreRed,
    block: 'block',
    inline: 'inline',
  },
  a: {
    color: colors.atmoreRed,
    fontSize: 'inherit',
    fontWeight: 700,
    textDecoration: 'underline',
  },
  blockquote: {
    fontSize: '1.5rem',
    backgroundColor: colors.inputBackgroundGrey,
    borderRadius: '0.25rem',
    color: colors.darkerGrey,
    fontStyle: 'italic',
    lineHeight: '1.25',
    padding: '1rem 1rem 1rem 1.5rem',
    position: 'relative',
  },
  headers: {
    fontFamily:
      '"Knockout 48 A", "Knockout 48 B", "Arial Narrow", Arial, sans-serif',
    textTransform: 'uppercase',
    letterSpacing: '0.1875rem',
    lineHeight: '1',
    fontWeight: 400,
    h1: {
      fontSize: '1.5rem',
      color: colors.darkerGrey,
      letterSpacing: '0.125rem',
    },
    h2: {
      fontSize: '3.75rem',
      color: colors.atmoreRed,
    },
    h3: {
      fontSize: '3rem',
      color: colors.atmoreRed,
    },
    h4: {
      fontSize: '1.125rem',
      fontFamily: 'proxima-nova, Arial, sans-serif',
      fontWeight: 700,
      color: colors.darkerGrey,
      letterSpacing: 0,
      textTransform: 'none',
    },
    h5: {
      fontSize: '1.125rem',
      fontFamily: 'proxima-nova, Arial, sans-serif',
      fontWeight: 700,
      color: colors.atmoreRed,
      letterSpacing: 0,
      textTransform: 'none',
      lineHeight: 1.222,
    },
    h6: {
      fontFamily: 'proxima-nova, Arial, sans-serif',
      fontStyle: 'normal',
      fontSize: '1rem',
      color: colors.darkerGrey,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 1.062,
      textTransform: 'none',
    },
  },
  lists: {
    display: 'block',
    withClassName: {
      listStyle: 'none',
      padding: 0,
    },
    ul: {
      listStyleType: 'disc',
      padding: '0 0 0 1.625rem',
    },
    ol: {
      listStyleType: 'decimal',
      padding: '0 0 0 2.5rem',
    },
    doubleLi: {
      marginTop: '.5rem',
    },
  },
  detailsSummary: {
    fontFamily:
      '"Knockout 48 A", "Knockout 48 B", "Arial Narrow", Arial, sans-serif',
    fontStyle: 'normal',
    fontSize: '1.3125rem',
    appearance: 'none',
    border: '0.125rem solid',
    cursor: 'pointer',
    fontWeight: 500,
    letterSpacing: '3px',
    maxWidth: '100%',
    textAlign: 'center',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    borderRadius: '0.375rem',
    display: 'inline-block',
    textDecoration: 'none',
    transition: 'background .15s ease-in',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: '#a32b29',
    padding: '0',
    width: 'auto',

    open: {
      marginBottom: '1rem',
    },
    iconRemoval: {
      listStyle: 'none',
      display: 'none',
    },
  },
  svg: {
    icon: {
      transform: 'translateY(-0.125rem)',
    },
  },
  arrow: {
    fontSize: '1.5em',
  },
  table: {
    headerColor: colors.atmoreDarkRed,
    activeRowBackgroundColor: colors.atmoreDarkRed,
    hoverRowBackgroundColor: rgba(255, 255, 255, 0.5),
  },
  forms: {
    input: {
      focusOutline: `0 0 0 0.2rem ${lighten(0.05, colors.atmoreLightRed)}`,
      borderColor: colors.borderGrey,
      disabledBackgroundColor: colors.disabledInput,
      backgroundColor: colors.inputBackgroundGrey,
    },
    select: {
      borderColor: colors.borderGrey,
      disabledBackgroundColor: colors.disabledInput,
      backgroundColor: colors.inputBackgroundGrey,
    },
    label: {
      fontFamily: 'proxima-nova',
      color: colors.atmoreRed,
    },
    fieldset: {
      color: colors.atmoreRed,
    },
    numberInput: {
      buttonHoverBackgroundColor: colors.hoverGrey,
    },
    switch: {
      onColor: colors.atmoreRed,
      offColor: colors.atmoreLighterRed,
    },
    checkbox: {
      borderColor: colors.atmoreRed,
      selectedColor: colors.atmoreRed,
    },
  },
  modal: {
    headerColor: colors.atmoreDarkRed,
  },
  propertySelector: {
    hidden: false,
    hiddenOnMobile: true,
    linkToPropertySite: true,
  },
  eventCards: {
    dayBannerBackground: colors.atmoreRed,
    filterTabsBackground: colors.atmoreDarkRed,
    typeBannerBackground: colors.atmoreDarkRed,
  },
  select: {
    arrowColor: colors.atmoreRed,
  },
  alerts: {
    borderColor: colors.atmoreRed,
    fontColor: colors.atmoreRed,
    copyColor: colors.darkerGrey,
    primary: {
      backgroundColor: colors.lightTeal,
    },
    warning: {
      backgroundColor: colors.lightYellow,
    },
    danger: {
      backgroundColor: colors.lightRed,
    },
  },
};

export default {
  // we use lodash's merge() func on each theme to override the themeDefaults with theme specific overrides recursively.
  // Using merge instead of assign here to prevent accidental overrides/missing values.
  [THEME_WCC]: merge({}, themeDefaults, {}),
  [THEME_WCC_RESPONSIVE]: merge({}, themeDefaults, {
    name: THEME_WCC_RESPONSIVE,
  }),
  [THEME_ATMORE]: merge({}, themeDefaults, {
    name: THEME_ATMORE,
  }),
  [THEME_MONTGOMERY]: merge({}, themeDefaults, {
    name: THEME_MONTGOMERY,
  }),
  [THEME_WETUMPKA]: merge({}, themeDefaults, {
    name: THEME_WETUMPKA,
  }),
  [THEME_BETHLEHEM]: merge({}, themeDefaults, {
    name: THEME_BETHLEHEM,
  }),
  [THEME_ARUBA]: merge({}, themeDefaults, {
    name: THEME_ARUBA,
  }),
  [THEME_CURACAO]: merge({}, themeDefaults, {
    name: THEME_CURACAO,
  }),
  [THEME_WASHESHU]: merge({}, themeDefaults, {
    name: THEME_WASHESHU,
  }),
};
