import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import { switchProp } from 'styled-tools';
import {
  PROMOTION_EVENT,
  SPECIAL_EVENT,
  ENTERTAINMENT_EVENT,
} from 'utils/constants';

const PromotionIcon = styled(FontAwesomeIcon)`
  font-size: 2vw;
  margin-bottom: 0.5vw;

  @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
    margin-bottom: 2.25vw;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    font-size: 0.75vw;
    margin-bottom: 0.3vw;
  }
  @media screen and (min-width: ${props =>
      props.theme.breakpoints.extraLarge}) {
    margin-bottom: 0.2vw;
  }

  color: ${props =>
    switchProp(
      'type',
      {
        [PROMOTION_EVENT]: props.theme.colors.promotionEvent,
        [SPECIAL_EVENT]: props.theme.colors.specialEvent,
        [ENTERTAINMENT_EVENT]: props.theme.colors.entertainmentEvent,
      },
      props.theme.colors.atmoreRed
    )}};
`;

export default PromotionIcon;
