import React from 'react';
import styled, { css } from 'styled-components/macro';
import { prop, switchProp } from 'styled-tools';
import EventFilter from './EventFilter';
import { formatMonthDayYear } from 'routes/HomePage/components/formatDate';
import {
  FULL_VIEW,
  UPCOMING_EVENTS,
  UPCOMING_EVENTS_WITH_FEATURED,
  UPCOMING_EVENTS_WITH_CALENDAR,
  ENTERTAINMENT_CALENDAR,
  ENTERTAINMENT_CALENDAR_WITH_DETAILS,
} from 'utils/constants';
import { isUpcomingEventsView } from 'utils/viewModeUtils';

const StyledEventList = styled.ul`
  overflow-y: auto;
  width: 100%;
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
  list-style: none;

  ${switchProp('viewMode', {
    [FULL_VIEW]: css`
      @media screen and (min-width: ${prop('theme.breakpoints.large')}) {
        background: ${prop('theme.colors.darkGrey')};
        box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.25) inset;
        overflow-y: auto;

        &&& {
          padding: 1rem 1.5rem;
        }
      }
    `,
    [UPCOMING_EVENTS]: css`
      display: flex;
      overflow: hidden;
      background: transparent;
      box-shadow: none;
    `,
    [UPCOMING_EVENTS_WITH_CALENDAR]: css`
      display: flex;
      overflow: hidden;
      background: transparent;
      box-shadow: none;
      margin-top: 3.75rem;
    `,
    [UPCOMING_EVENTS_WITH_FEATURED]: css`
      display: flex;
      overflow-x: auto;
      background: transparent;
      box-shadow: none;
      width: 50%;
      flex: 1 0 auto;
      overflow: hidden;
    `,
    [ENTERTAINMENT_CALENDAR]: css`
      @media screen and (min-width: ${prop('theme.breakpoints.large')}) {
        background: ${prop('theme.colors.darkGrey')};
        box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.25) inset;
        overflow-y: auto;

        &&& {
          padding: 4.9rem 1.5rem;
        }
      }
    `,
    [ENTERTAINMENT_CALENDAR_WITH_DETAILS]: css`
      @media screen and (min-width: ${prop('theme.breakpoints.large')}) {
        background: ${prop('theme.colors.darkGrey')};
        box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.25) inset;
        overflow-y: auto;

        &&& {
          padding: 4.9rem 1.5rem;
        }
      }
    `,
  })}
`;

const DayBanner = styled.div`
  background: ${prop('theme.eventCards.dayBannerBackground')};
  font-family: 'Knockout 48 A', 'Knockout 48 B', 'Arial Narrow', Arial,
    sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.14rem;
  text-transform: uppercase;
  color: ${prop('theme.colors.white')};
  padding: 1rem;
  border-radius: 0.25rem 0.25rem 0 0;
  display: none;
  position: sticky;
  z-index: 1000;
  line-height: 1.25;

  @media screen and (min-width: ${prop('theme.breakpoints.large')}) {
    display: block;
  }
`;

const HeaderTitle = styled.span`
  vertical-align: baseline;
`;

function EventList({
  selectedDate,
  children,
  tab,
  eventListRef,
  setTypeFilter,
  typeFilter,
  viewMode,
}) {
  const formattedDate = formatMonthDayYear('en-US', new Date(selectedDate));

  return (
    <>
      {!isUpcomingEventsView(viewMode) &&
        viewMode !== ENTERTAINMENT_CALENDAR &&
        viewMode !== ENTERTAINMENT_CALENDAR_WITH_DETAILS && (
          <DayBanner>
            <HeaderTitle>
              {selectedDate
                ? formattedDate + ' Promotions'
                : 'UPCOMING PROMOTIONS & EVENTS'}
            </HeaderTitle>
            <EventFilter
              setTypeFilter={setTypeFilter}
              typeFilter={typeFilter}
              show
            />
          </DayBanner>
        )}
      <StyledEventList
        tab={tab}
        ref={eventListRef}
        viewMode={viewMode}
        setTypeFilter={setTypeFilter}
        typeFilter={typeFilter}
      >
        {children}
      </StyledEventList>
    </>
  );
}

export default EventList;
