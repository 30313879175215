import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { ifNotProp } from 'styled-tools';
import AddAFriendIcon from './images/AddAFriend_51x48.png';
import AllMine from './images/AllMine_51x48.png';
import AutoAdvanceIcon from './images/AutoAdvance_51x48.png';
import AutoAdvancePlusIcon from './images/AutoAdvance_Plus_51x48.png';
import AutoWinIcon from './images/AutoWin_51x48.png';
import BonusGame from './images/BonusGame.png';
import BouncebackIcon from './images/BounceBack_51x48.png';
import CallMyNameIcon from './images/CallMyName_51x48.png';
import CashIsKingIcon from './images/CashIsKing_51x48.png';
import DeckedOutIcon from './images/DeckedOut_51x48.png';
import ExtraEntriesIcon from './images/ExtraEntries_51x48.png';
import ExtraExtraIcon from './images/ExtraExtra_51x48.png';
import EarnGet from './images/EarnGet.png';
import ElectricDrawing from './images/ElectricDrawing.png';
import DoOverIcon from './images/DoOver_51x48.png';
import FoodCredit from './images/FoodCredit.png';
import FirstPickIcon from './images/FirstPick_51x48.png';
import FreePlayImg from './images/FreePlay.png';
import FreeForMe from './images/FreeForMe_51x48.png';
import FreeEntryIcon from './images/FreeEntry_51x48.png';
import FreeFoodFrenzy from './images/FreeFoodFrenzy_51x48.png';
import FreeSpinIcon from './images/FreeSpin_51x48.png';
import HotSeats from './images/HotSeats.png';
import HolidayShoppingDollars from './images/HolidayShoppingDollars.png';
import JetSetter from './images/JetSetter_51x48.png';
import MysteryBoxIcon from './images/MysteryBox_51x48.png';
import OhSnap from './images/OhSnap_51x48.png';
import OnlineDrawing from './images/OnlineDrawing.png';
import OnlineTournament from './images/OnlineTournament.png';
import PrizeBoosterIcon from './images/PrizeBooster_51x48.png';
import PrizeDoublerIcon from './images/PrizeDoubler_51x48.png';
import PrizeMultiplierIcon from './images/PrizeMultiplier_51x48.png';
import ShareTheWin from './images/ShareTheWin_51x48.png';
import SpecialEventImg from './images/SpecialEvent.png';
import SpaSpecialImg from './images/SpaSpecial.png';
import StandBy from './images/StandBy_51x48.png';
import TicketDrawing from './images/TicketDrawing.png';
import TournamentImg from './images/TournamentImg.png';
import Triggered from './images/Triggered_51x48.png';
import VirtualWinIcon from './images/VirtualWin_51x48.png';
import Waterfall from './images/Waterfall_51x48.png';
import WinFromHomeIcon from './images/WinFromHome_51x48.png';
import VersePerksAutoSpinIcon from './images/VersePerks_AutoSpin_51x48.png';
import VersePerksAutoWinIcon from './images/VersePerks_AutoWin_51x48.png';
import VersePerksCallMyNameIcon from './images/VersePerks_CallMyName_51x48.png';
import VersePerksCurrencyExchangeIcon from './images/VersePerks_CurrencyExchange_51x48.png';
import VersePerksDoubleUScoreIcon from './images/VersePerks_DoubleUScore_51x48.png';
import VersePerksFreeSpinIcon from './images/VersePerks_FreeSpin_51x48.png';
import VersePerksMagnetIcon from './images/VersePerks_Magnet_51x48.png';
import VersePerksPrizeDoublerIcon from './images/VersePerks_PrizeDoubler_51x48.png';
import VersePerksRepeatChallengeIcon from './images/VersePerks_RepeatChallenge_51x48.png';
import VersePerksReSpinWheelIcon from './images/VersePerks_ReSpinWheel_51x48.png';
import VersePerksTimeWarpIcon from './images/VersePerks_TimeWarp_51x48.png';
import VersePerksWMoreIcon from './images/VersePerks_WMore_51x48.png';

import {
  AUTO_WIN,
  BONUS_GAME,
  HOT_SEATS,
  EARN_GET,
  ELECTRONIC_DRAWING,
  FOOD_CREDIT,
  TICKET_DRAWING,
  HOLIDAY_SHOPPING_DOLLARS,
  COME_AND_GET,
  FREE_PLAY,
  SPA_SPECIAL,
  SPECIAL_EVENT,
  TOURNAMENT,
  PRIZE_MULTIPLIER,
  AUTO_ADVANCE,
  AUTO_ADVANCE_PLUS,
  PRIZE_BOOSTER,
  PRIZE_DOUBLER,
  PICK_FIRST,
  VIRTUAL_WIN,
  WIN_FROM_HOME,
  ADD_A_FRIEND,
  MYSTERY_BOX,
  CASH_IS_KING,
  EXTRA_ENTRIES,
  EXTRA_EXTRA,
  FREE_ENTRY,
  DECKED_OUT,
  ALL_MINE,
  FREE_FOR_ME,
  TRIGGERED,
  FREE_FOOD_FRENZY,
  WATERFALL,
  SHARE_THE_WIN,
  OH_SNAP,
  ONLINE_TOURNAMENT,
  ONLINE_DRAWING,
  JETSETTER,
  STAND_BY,
  BOUNCEBACK,
  DO_OVER,
  FREE_SPIN,
  CALL_MY_NAME,
  VERSE_PERKS_AUTO_SPIN,
  VERSE_PERKS_AUTO_WIN,
  VERSE_PERKS_CALL_MY_NAME,
  VERSE_PERKS_CURRENCY_EXCHANGE,
  VERSE_PERKS_DOUBLEU_SCORE,
  VERSE_PERKS_FREE_SPIN,
  VERSE_PERKS_MAGNET,
  VERSE_PERKS_PRIZE_DOUBLER,
  VERSE_PERKS_REPEAT_CHALLENGE,
  VERSE_PERKS_RESPIN_WHEEL,
  VERSE_PERKS_TIME_WARP,
  VERSE_PERKS_WMORE,
} from 'utils/constants';
import styled from 'styled-components/macro';

const propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  isPromoPerk: PropTypes.bool,
  isVersePerk: PropTypes.bool,
};

const PROMOTION_ICON = {
  [BONUS_GAME]: BonusGame,
  [HOT_SEATS]: HotSeats,
  [EARN_GET]: EarnGet,
  [ELECTRONIC_DRAWING]: ElectricDrawing,
  [FOOD_CREDIT]: FoodCredit,
  [TICKET_DRAWING]: TicketDrawing,
  [HOLIDAY_SHOPPING_DOLLARS]: HolidayShoppingDollars,
  [COME_AND_GET]: EarnGet,
  [FREE_PLAY]: FreePlayImg,
  [SPECIAL_EVENT]: SpecialEventImg,
  [SPA_SPECIAL]: SpaSpecialImg,
  [TOURNAMENT]: TournamentImg,
  [PRIZE_MULTIPLIER]: PrizeMultiplierIcon,
  [AUTO_ADVANCE]: AutoAdvanceIcon,
  [AUTO_ADVANCE_PLUS]: AutoAdvancePlusIcon,
  [AUTO_WIN]: AutoWinIcon,
  [PRIZE_BOOSTER]: PrizeBoosterIcon,
  [PRIZE_DOUBLER]: PrizeDoublerIcon,
  [PICK_FIRST]: FirstPickIcon,
  [VIRTUAL_WIN]: VirtualWinIcon,
  [WIN_FROM_HOME]: WinFromHomeIcon,
  [ADD_A_FRIEND]: AddAFriendIcon,
  [MYSTERY_BOX]: MysteryBoxIcon,
  [CASH_IS_KING]: CashIsKingIcon,
  [EXTRA_ENTRIES]: ExtraEntriesIcon,
  [EXTRA_EXTRA]: ExtraExtraIcon,
  [FREE_ENTRY]: FreeEntryIcon,
  [DECKED_OUT]: DeckedOutIcon,
  [ALL_MINE]: AllMine,
  [FREE_FOR_ME]: FreeForMe,
  [TRIGGERED]: Triggered,
  [FREE_FOOD_FRENZY]: FreeFoodFrenzy,
  [WATERFALL]: Waterfall,
  [SHARE_THE_WIN]: ShareTheWin,
  [OH_SNAP]: OhSnap,
  [ONLINE_TOURNAMENT]: OnlineTournament,
  [ONLINE_DRAWING]: OnlineDrawing,
  [JETSETTER]: JetSetter,
  [STAND_BY]: StandBy,
  [BOUNCEBACK]: BouncebackIcon,
  [DO_OVER]: DoOverIcon,
  [FREE_SPIN]: FreeSpinIcon,
  [CALL_MY_NAME]: CallMyNameIcon,
};

const VERSE_PERK_ICON = {
  [VERSE_PERKS_AUTO_SPIN]: VersePerksAutoSpinIcon,
  [VERSE_PERKS_AUTO_WIN]: VersePerksAutoWinIcon,
  [VERSE_PERKS_CALL_MY_NAME]: VersePerksCallMyNameIcon,
  [VERSE_PERKS_CURRENCY_EXCHANGE]: VersePerksCurrencyExchangeIcon,
  [VERSE_PERKS_DOUBLEU_SCORE]: VersePerksDoubleUScoreIcon,
  [VERSE_PERKS_FREE_SPIN]: VersePerksFreeSpinIcon,
  [VERSE_PERKS_MAGNET]: VersePerksMagnetIcon,
  [VERSE_PERKS_PRIZE_DOUBLER]: VersePerksPrizeDoublerIcon,
  [VERSE_PERKS_REPEAT_CHALLENGE]: VersePerksRepeatChallengeIcon,
  [VERSE_PERKS_RESPIN_WHEEL]: VersePerksReSpinWheelIcon,
  [VERSE_PERKS_TIME_WARP]: VersePerksTimeWarpIcon,
  [VERSE_PERKS_WMORE]: VersePerksWMoreIcon,
};

const PROMOTION_DESCRIPTION = {
  [AUTO_WIN]: 'Auto Win',
  [BONUS_GAME]: 'Bonus Game',
  [HOT_SEATS]: 'Hot Seats',
  [EARN_GET]: 'Earn & Get',
  [ELECTRONIC_DRAWING]: 'Electronic Drawing',
  [FOOD_CREDIT]: 'Food Credit',
  [TICKET_DRAWING]: 'Drawing',
  [HOLIDAY_SHOPPING_DOLLARS]: 'Holiday Shopping Dollars',
  [COME_AND_GET]: 'Come and Get',
  [FREE_PLAY]: 'FREE Play',
  [SPECIAL_EVENT]: 'Special Event',
  [SPA_SPECIAL]: 'Spa Special',
  [TOURNAMENT]: 'Tournament',
  [FREE_FOOD_FRENZY]: 'Free Food Frenzy',
  [ONLINE_TOURNAMENT]: 'Online Tournament',
  [ONLINE_DRAWING]: 'Online Drawing',
  [DO_OVER]: 'Do Over',
};

const StyledPromotionType = styled.li`
  font-size: 0.75rem;
  color: ${props => props.theme.colors.darkerGrey};
  font-weight: 700;
  letter-spacing: 0.125rem;
  text-transform: uppercase;

  img {
    max-width: 1.5rem;

    ${({ isPromoPerk, isVersePerk }) =>
      !isPromoPerk && !isVersePerk
        ? css`
            filter: brightness(0) saturate(100%) invert(18%) sepia(67%)
              saturate(2556%) hue-rotate(342deg) brightness(99%) contrast(89%);
          `
        : undefined}
  }
`;

function PromotionType({ type, name, isPromoPerk, isVersePerk }) {
  return (
    <StyledPromotionType isPromoPerk={isPromoPerk} isVersePerk={isVersePerk}>
      <img
        src={isVersePerk ? VERSE_PERK_ICON[type] : PROMOTION_ICON[type]}
        alt={PROMOTION_DESCRIPTION[type] || name}
        title={PROMOTION_DESCRIPTION[type]}
      />{' '}
      <span>{PROMOTION_DESCRIPTION[type] || name}</span>
    </StyledPromotionType>
  );
}

PromotionType.propTypes = propTypes;

export default PromotionType;
