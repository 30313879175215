import styled, { css } from 'styled-components';

export function triggerMixin(theme) {
  return css`
    font-size: ${theme.buttons.trigger.fontSize};
    background-color: ${theme.buttons.trigger.backgroundColor};
    border-color: ${theme.buttons.trigger.borderColor};
    border-radius: ${theme.buttons.trigger.borderRadius};
    color: ${theme.buttons.trigger.color};
    height: ${theme.buttons.trigger.height};
    letter-spacing: ${theme.buttons.trigger.letterSpacing};
    line-height: ${theme.buttons.trigger.lineHeight};
    padding: ${theme.buttons.trigger.padding};
    text-align: ${theme.buttons.trigger.textAlign};
    width: ${theme.buttons.trigger.width};

    &:hover {
      background-color: ${props =>
        props.theme.buttons.trigger.focusHover.backgroundColor};
    }

    svg {
      vertical-align: bottom;
      height: 100%;

      path {
        color: ${theme.buttons.trigger.iconColor};
      }
    }
  `;
}
const Button = styled.button`
  ${({ theme }) => css`
    font-family: ${theme.buttons.fontFamily};
    font-style: ${theme.buttons.fontStyle};
    font-size: ${theme.buttons.fontSize};
    border: ${theme.buttons.border};
    border-radius: ${theme.buttons.borderRadius};
    cursor: ${theme.buttons.cursor};
    display: ${theme.buttons.display};
    font-weight: ${theme.buttons.fontWeight};
    letter-spacing: ${theme.buttons.letterSpacing};
    max-width: ${theme.buttons.maxWidth};
    padding: ${theme.buttons.padding};
    text-align: ${theme.buttons.textAlign};
    text-transform: ${theme.buttons.textTransform};
    text-decoration: ${theme.buttons.textDecoration};
    transition: ${theme.buttons.transition};
    white-space: ${theme.buttons.whiteSpace};
    width: ${theme.buttons.width};

    &:focus,
    &:hover {
      box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.2);
    }

    &.primary {
      background-color: ${theme.buttons.primary.backgroundColor};
      border-color: ${theme.buttons.primary.borderColor};
      color: ${theme.buttons.primary.color};

      &:focus,
      &:hover {
        background-color: ${props =>
          props.theme.buttons.primary.focusHover.backgroundColor};
      }
    }
    &.white {
      background-color: ${theme.buttons.white.backgroundColor};
      border-color: ${theme.buttons.white.borderColor};
      color: ${theme.buttons.white.color};

      &:focus,
      &:hover {
        background-color: ${props =>
          props.theme.buttons.white.focusHover.backgroundColor};
        border-color: ${props =>
          props.theme.buttons.white.focusHover.borderColor};
        color: ${theme.buttons.white.focusHover.color};
      }
    }
    &.outline {
      background-color: ${theme.buttons.outline.backgroundColor};
      border-color: ${theme.buttons.outline.borderColor};
      color: ${theme.buttons.outline.color};
      transition: ${theme.buttons.outline.transition};
      transition-duration: ${props =>
        props.theme.buttons.outline.transitionDuration};
      transition-timing-function: ${props =>
        props.theme.buttons.outline.transitionTimingFunction};

      &:focus,
      &:hover {
        background-color: ${props =>
          props.theme.buttons.outline.focusHover.backgroundColor};
        border-color: ${props =>
          props.theme.buttons.outline.focusHover.borderColor};
        color: ${theme.buttons.outline.focusHover.color};
      }
    }
    &.small {
      width: ${theme.buttons.small.width};
    }
    &.icon-only {
      height: ${theme.buttons.iconOnly.height};
      width: ${theme.buttons.iconOnly.width};
      padding: ${theme.buttons.iconOnly.padding};
    }
    &.text {
      background-color: ${theme.buttons.text.backgroundColor};
      border-color: ${theme.buttons.text.borderColor};
      border-radius: ${theme.buttons.text.borderRadius};
      color: ${theme.buttons.text.color};
      padding: ${theme.buttons.text.padding};
      text-decoration: ${theme.buttons.text.textDecoration};
      width: ${theme.buttons.text.width};

      &:focus,
      &:hover {
        text-decoration: ${props =>
          props.theme.buttons.text.focusHover.textDecoration};
        box-shadow: none;
      }
    }
    &.trigger {
      ${triggerMixin(theme)}
    }

    &[disabled] {
      opacity: ${theme.buttons.disabled.opacity};
      cursor: ${theme.buttons.disabled.cursor};

      &:focus,
      &:hover {
        box-shadow: ${theme.buttons.disabled.focusHover.boxShadow};
      }
    }
  `}
`;

export default Button;
