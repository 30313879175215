export function isSuperAdmin(user) {
  return user && user.roles && user.roles.includes('super-admin');
}

export function isHostEmployee(user) {
  return user && user.roles && user.roles.includes('host-employee');
}

export function isHotelBookingAdmin(user) {
  return user && user.roles && user.roles.includes('hotel-booking-admin');
}

export function isHotelWaitlistApprover(user) {
  return user && user.roles && user.roles.includes('hotel-waitlist-approver');
}

export function isCommunityEventsAdmin(user) {
  return user && user.roles && user.roles.includes('community-events-admin');
}

export function isFrontLineEmployee(user) {
  return user && user.roles && user.roles.includes('front-line-employee');
}

export function isHostEmployeeManager(user) {
  return user && user.roles && user.roles.includes('host-employee-manager');
}

export function canSeeEmployeeOnlyEvents(user) {
  return (
    isSuperAdmin(user) ||
    isFrontLineEmployee(user) ||
    isHostEmployee(user) ||
    isHostEmployeeManager(user) ||
    isHotelWaitlistApprover(user) ||
    isCommunityEventsAdmin(user) ||
    isHotelBookingAdmin(user) ||
    isSuperAdmin(user)
  );
}
