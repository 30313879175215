import styled, { css } from 'styled-components/macro';
import { switchProp } from 'styled-tools';
import {
  UPCOMING_EVENTS,
  UPCOMING_EVENTS_WITH_FEATURED,
  UPCOMING_EVENTS_WITH_CALENDAR,
} from 'utils/constants';

const UpcomingEventsEmptyStyles = theme => css`
  background: ${theme.alerts.danger.backgroundColor};
  border-left: 0.5rem solid;
  border-radius: 0.5rem;
  border-left-color: ${theme.alerts.borderColor};
  color: ${theme.alerts.copyColor};
  box-shadow: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
  margin: 0;
  width: 100%;

  h3 {
    margin-bottom: 1rem;
  }
`;

const MessageCard = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    border-radius: 5px;
    margin: 0 0.75em 0.75em;
    padding: 0.75em;
    box-shadow: 0 0.25em 0.4em rgba(0, 0, 0, 0.25);
    text-align: center;

    ${switchProp('viewMode', {
      [UPCOMING_EVENTS_WITH_CALENDAR]: css`
        ${UpcomingEventsEmptyStyles(theme)}
        border: 0;
        background-color: ${theme.colors.lightGrey};
      `,
      [UPCOMING_EVENTS_WITH_FEATURED]: css`
        ${UpcomingEventsEmptyStyles(theme)}
      `,
      [UPCOMING_EVENTS]: css`
        ${UpcomingEventsEmptyStyles(theme)}
      `,
    })}
  `}
`;

export default MessageCard;
