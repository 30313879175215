import React from 'react';
import { Query } from '@apollo/react-components';
import moment from 'moment';
import gql from 'graphql-tag';
import { selectProperty } from 'routes/selectors';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import EventCard from './EventCard';
import MessageCard from './styled/MessageCard';
import { FEATURED_EVENT } from 'utils/constants';

const QUERY_GET_NEXT_FEATURED_EVENT = gql`
  query GetNextFeaturedEvent($input: NextFeaturedEventInput!) {
    getNextFeaturedEvent(input: $input) {
      eventId
      name
      property
      category {
        eventCategoryId
        displayName
        value
        lastModified
      }
      isFullDayEvent
      isRecurring
      startDate
      endDate
      startTime
      endTime
      isEarnPeriodRecurring
      earnPeriodStartDate
      earnPeriodEndDate
      earnPeriodStartTime
      earnPeriodEndTime
      earnPeriodDescription
      earnPeriodImageUrl
      shortDescription
      imageUrl
      imageAlt
      detailsUrl
      parentEventId
      createdDate
      lastModified
      promotionTypes
      recurringPattern {
        recurringType
        separationCount
        maxNumberOfOccurrences
        daysOfWeek
        weekOfMonth
        dayOfMonth
        monthOfYear
      }
      earnPeriodRecurringPattern {
        recurringType
        separationCount
        maxNumberOfOccurrences
        daysOfWeek
        weekOfMonth
        dayOfMonth
        monthOfYear
      }
      occurrenceDetails
      enabled
      shortDescriptionHeader
      howToQualifyHeader
      featuredImageUrl
      thumbnailImageUrl
      promoPerks {
        id
        name
        value
      }
      versePerks {
        id
        name
        value
      }
    }
  }
`;

function FeaturedEvent({ eventType, selectedDay, calendarTypes }) {
  const selectedProperty = useSelector(selectProperty);
  const calendarType =
    calendarTypes &&
    find(
      calendarTypes,
      calendarType => calendarType.queryParameter === eventType
    );
  const queryInput = calendarType && {
    propertyCode: selectedProperty,
    calendarTypeId: calendarType.id,
  };

  return (
    <Query
      query={QUERY_GET_NEXT_FEATURED_EVENT}
      variables={{ input: queryInput }}
      skip={!queryInput}
      notifyOnNetworkStatusChange
      fetchPolicy='network-only'
    >
      {({ data, loading }) => {
        return (
          <>
            {data && data.getNextFeaturedEvent && (
              <EventCard
                key={'featuredEvent'}
                selectedDay={moment(new Date(selectedDay))}
                event={data.getNextFeaturedEvent}
                viewMode={FEATURED_EVENT}
                featuredEvent={true}
              />
            )}
            {!loading && !(data && data.getNextFeaturedEvent) && (
              <MessageCard viewMode={FEATURED_EVENT}>
                <h3>No Events Scheduled</h3>
                <p>
                  Try again later or view the calendar for
                  <br />
                  other event options.
                </p>
              </MessageCard>
            )}
          </>
        );
      }}
    </Query>
  );
}

export default FeaturedEvent;
