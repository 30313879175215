import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';

const propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const defaultProps = {
  title: 'Maintenance Mode',
  message: 'Maintenance Mode, please come back soon!',
  isOpen: false,
};

const StyledMaintenanceModal = styled(Modal)`
  ${ifProp(
    'isMobile',
    css`
      margin: 0;
      width: 100%;
      height: 100%;

      .modal-content {
        height: 100%;
      }
    `
  )}

  .modal-content {
    border-radius: 0;
    border: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
    text-align: center;

    h1 {
      color: ${props => props.theme.modal.headerColor};
    }

    iframe {
      height: 100%;
      min-height: 400px;
    }
  }

  p {
    margin: 0;
    font-size: 0.8em;
    text-transform: uppercase;
    text-align: center;
  }
`;

function MaintenanceModeModal({ title, message, isOpen }) {
  return (
    <StyledMaintenanceModal
      isOpen={isOpen}
      size='md'
      centered
      autoFocus
      scrollable
    >
      <ModalBody>
        <h1>{title}</h1>
        <p>{message}</p>
      </ModalBody>
    </StyledMaintenanceModal>
  );
}

MaintenanceModeModal.propTypes = propTypes;
MaintenanceModeModal.defaultProps = defaultProps;

export default MaintenanceModeModal;
