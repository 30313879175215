import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import {
  ENTERTAINMENT_CALENDAR,
  ENTERTAINMENT_CALENDAR_WITH_DETAILS,
} from 'utils/constants';

const entertainmentCalendarStyle = css`
  .react-calendar__tile {
    text-align: center;

    abbr {
      font-size: 0.8rem;
    }
  }

  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    h5 {
      &,
      &.event-count {
        color: ${props => props.theme.colors.white};
      }
    }
  }

  .react-calendar__navigation {
    flex: 0 1 auto;
  }

  .react-calendar__month-view__weekdays {
    @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
      padding: 0;

      .react-calendar__month-view__weekdays__weekday {
        font-size: 0.75rem;
      }
    }
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.extraSmall}) {
    .react-calendar__tile {
      abbr {
        font-size: 1.5rem;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
    justify-content: flex-start;

    .react-calendar__tile {
      font-size: ${props => props.calendarDayFontSize};

      abbr {
        font-size: 1em;
      }
    }
  }
`;

const ReactCalendarWrapper = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;

  ${ifProp(
    'disabled',
    css`
      opacity: 0.5;
    `
  )}
  
  ${props =>
    props.weekView &&
    props.sticky &&
    css`
      position: fixed;
      top: 0;
      z-index: 1;
      width: 100%;
      background-color: ${props => props.theme.colors.white};
    `}
    
  ${props =>
    props.weekView &&
    css`
      .react-calendar {
        .react-calendar__navigation__arrow {
          display: none;
        }
      }
    `}

  /*
  We calculate the calendar height dynamically. This has to be a set value so the css
  transitions from Week View to Month View can work.
  */
  .react-calendar {
    transition: all 0.4s ease-in-out;
    border: 0;
    display: flex;
    flex-direction: column;
    font-size: 0.6rem;
    width: 100%;
    height: ${props => props.calendarHeight}px;

    ${props =>
      props.weekView &&
      css`
        height: 9rem;
      `}
    
    @media screen and (min-width: ${props =>
      props.theme.breakpoints.extraSmall}) {

      ${props =>
        props.weekView &&
        css`
          height: 10.5rem;
        `}
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
      ${props =>
        props.weekView &&
        css`
          height: 12.5rem;
        `}
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.medium}) {
      ${props =>
        props.weekView &&
        css`
          height: 14.5rem;
        `}
    }

    @media screen and (min-width: ${props =>
      props.theme.breakpoints.extraLarge}) {
      height: auto;
    }
  }

  .react-calendar__month-view {
    display: flex;
    transition: all 0.4s ease-in-out;
    height: auto;

    ${props =>
      props.weekView &&
      css`
        min-height: auto;
        height: 7rem;

        & > div > div {
          height: auto;
        }
      `}

    @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
      ${props =>
        props.weekView &&
        css`
          min-height: auto;
          height: 9rem;

          & > div > div {
            height: auto;
          }
        `}
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.medium}) {
      ${props =>
        props.weekView &&
        css`
          min-height: auto;
          height: 11rem;

          & > div > div {
            height: auto;
          }
        `}
    }
  
    ${props =>
      props.hideCalendar &&
      css`
        display: none;
        min-height: auto;

        & > div > div {
          height: auto;
        }
      `}

    & > div {
      width: 100%;
    }

    & > div > div {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.medium}) {
      flex: 0 1 auto;
      min-height: 12.5rem;
    }
  }

  .react-calendar + .react-calendar {
    .react-calendar__navigation__arrow {
      display: none;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.large}) {
      display: none;
    }
  }

  .react-calendar .tile abbr {
    display: none;
  }

  .react-calendar .tile {
    background: none;
    border: none;
    margin: 0;
    padding: 0.125rem;
    cursor: pointer;
  }

  .react-calendar__tile {
    background: ${props => props.theme.colors.lightGrey};
    text-align: right;
    cursor: pointer;
    position: relative;
    border: 0.0625em solid ${props => props.theme.colors.white};
    border-radius: 0.25rem;
    height: ${props => props.tileHeight};
    opacity: 1;
    transition: all 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;

    &:hover {
      background: ${props => props.theme.colors.lightGrey};
    }

    abbr {
      font-size: 1.5rem;
      line-height: 1;
      color: ${props => props.theme.colors.black};
      font-weight: bold;
    }

    &.disabled:not(.selectedWeek) {
      ${props =>
        props.weekView &&
        css`
          opacity: 1;
          height: 0;
          min-height: 0;
          padding: 0;
          margin: 0;
          border-width: 0;
          transition: all 0.4s ease-in-out;
        `}
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
      padding: 0.25rem;
    }

    @media screen and (min-width: ${props =>
      props.theme.breakpoints.extraLarge}) {
      padding: 0.5rem;
    }
  }

  .react-calendar__month-view__weekdays {
    .react-calendar__month-view__weekdays__weekday {
      text-transform: uppercase;
      color: ${props => props.theme.calendar.fontColor};
      font-weight: bold;
      font-size: 0.75rem;
      padding: 0.25rem 0;
      letter-spacing: 0.1rem;

      abbr {
        text-decoration: none;
      }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
      padding: 0.5em 0;

      .react-calendar__month-view__weekdays__weekday {
        font-size: 0.7rem;
      }
    }
  }

  .react-calendar__tile[disabled] {
    opacity: .5;
  }

  .react-calendar__navigation__label {
    border: 0;
    background: transparent;
    color: ${props => props.theme.calendar.fontColor};
    font-size: 1rem;
    font-weight: bold;

    /* TODO: See if there is a way to override this without the important */
    flex-grow: unset !important;

    &[disabled] {
      background: transparent;
    }
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    background: transparent;
    border-radius: 0.2rem;
    border: 0;
    cursor: pointer;
    padding: 0 1.2rem;
    height: 100%;
    font-size: 1.5rem;
    line-height: 1rem;
    color: ${props => props.theme.colors.atmoreRed};

    &:disabled {
      opacity: 0.5;
    }

    &:hover {
      background: transparent;
    }
  }

  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    &,
    &:focus,
    &:active,
    &:hover {
      background-color: ${props =>
        props.theme.calendar.tile.activeBackgroundColor};
    }
  }

  .react-calendar__tile--hasActive .content,
  .react-calendar__tile--active .content {
    color: ${props => props.theme.colors.white};
    
    a {
      color: ${props => props.theme.colors.white};
    }
  }

  .react-calendar__tile--hasActive abbr,
  .react-calendar__tile--active abbr {
    font-weight: bold;
    color: ${props => props.theme.colors.white};
  }

  .border-icon {
    display: none;
  }

  .react-calendar__tile--active {
    .border-icon {
      display: inline-block;
    }
  }

  .react-calendar__navigation {
    padding: 0.3rem;
    width: 100%;
    position: relative;
    right: 0;
    align-self: flex-end;
    justify-content: center;
    height: 3.125rem;
    flex: 1 0 auto;

    button:enabled:hover,
    button:enabled:focus {
      background-color: transparent;
    }
  }

  .react-calendar__month-view__days {
    padding-left: 0;
    padding-right: 0;
    height: auto;

    ${props =>
      props.weekView &&
      css`
        height: 100%;
      `}
  }

  @media screen and (min-width: ${props =>
    props.theme.breakpoints.extraSmall}) {
    flex: 0 1 auto;

    .react-calendar {
      font-size: 0.75rem;
      flex: 1 0 auto;
    }
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: .5;
    pointer-events: none;
  }

  /* 
  Styles specific to the Entertainment Calendar view
  */
  ${ifProp({ viewMode: ENTERTAINMENT_CALENDAR }, entertainmentCalendarStyle)}
  
  ${ifProp(
    { viewMode: ENTERTAINMENT_CALENDAR_WITH_DETAILS },
    entertainmentCalendarStyle
  )}
`;

export default ReactCalendarWrapper;
