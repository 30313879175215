import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Calendar as ReactCalendar } from 'react-calendar';
import moment from 'moment';
import ReactCalendarWrapper from './styled/ReactCalendarWrapper';
import SpinnerContainer from './styled/SpinnerContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DayTile from './DayTile';
import { getTheme, getThemeName } from 'utils/themeUtils';
import _ from 'lodash';
import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import {
  DAYS_OF_WEEK,
  FRIDAY,
  MINI_CALENDAR,
  SATURDAY,
  UPCOMING_EVENTS_WITH_CALENDAR,
  ENTERTAINMENT_CALENDAR,
  ENTERTAINMENT_CALENDAR_WITH_DETAILS,
} from 'utils/constants';
import Button from 'components/Button';
import EventFilter from 'routes/HomePage/components/EventFilter';
import { getFormatMonthYear } from 'routes/HomePage/components/formatDate';

const propTypes = {
  selectedDay: PropTypes.instanceOf(Date),
  onDatesChange: PropTypes.func,
  onClickDay: PropTypes.func,
  setQueryInput: PropTypes.func,
  queryInput: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    property: PropTypes.string,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      date: PropTypes.string.isRequired,
      type: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
  error: PropTypes.object,
  property: PropTypes.string,
  activeStartDate: PropTypes.any,
  selectedWeek: PropTypes.number,
  setSelectedWeek: PropTypes.func,
  setSelectedDay: PropTypes.func,
  handleSetIsMobile: PropTypes.func,
  disabled: PropTypes.bool,
  setTypeFilter: PropTypes.func.isRequired,
  typeFilter: PropTypes.array.isRequired,
  viewMode: PropTypes.string,
};

const TILE_WIDTH_PERCENTAGE = 0.142857;
const CALENDAR_HEIGHT_PADDING = 80;

const CloseButton = styled(Button)`
  opacity: 0;
  transition: all 0.4s ease-in-out;

  &&& {
    width: fit-content;
    height: 0;
    outline: 0;
  }

  ${ifProp(
    'show',
    css`
      &&& {
        opacity: 1;
        height: 2.5rem;
      }
    `
  )}

  @media screen and (max-width: 350px) {
    &&& {
      font-size: 1.35rem;
    }
  }
`;

const StyledDiv = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: space-between;
`;

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarDayFontSize: '1em',
      isMobile: null,
      sticky: false,
    };

    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    const { handleSetIsMobile } = this.props;
    const theme = getTheme(getThemeName());
    const largeBreakpoint = parseInt(theme.breakpoints.large, 10);
    const vw = document.documentElement.clientWidth;

    this.setState({
      isMobile: vw < largeBreakpoint,
    });

    handleSetIsMobile(vw < largeBreakpoint);

    if (!document.addEventListener && !window.attachEvent) {
      return;
    }

    if (window.attachEvent) {
      window.attachEvent('onresize', this.setCalendarDayFontSize, false);
      window.attachEvent('onload', this.setCalendarDayFontSize, false);
    } else if (window.addEventListener) {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.setCalendarDayFontSize, false);
      document.addEventListener(
        'DOMContentLoaded',
        this.setCalendarDayFontSize,
        false
      );
    }
  }

  componentWillUnmount() {
    if (window.detachEvent) {
      window.detachEvent('onresize', this.setCalendarDayFontSize, false);
      window.detachEvent('onload', this.setCalendarDayFontSize, false);
      window.detachEvent('scroll', this.handleScroll, false);
    } else if (window.removeEventListener) {
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.setCalendarDayFontSize, false);
      document.removeEventListener(
        'DOMContentLoaded',
        this.setCalendarDayFontSize,
        false
      );
    }
  }

  setCalendarDayFontSize = () => {
    const { handleSetIsMobile } = this.props;
    const tiles = document.getElementsByClassName('react-calendar__tile');
    const theme = getTheme(getThemeName());
    const largeBreakpoint = parseInt(theme.breakpoints.large, 10);
    const vw = document.documentElement.clientWidth;

    handleSetIsMobile(vw < largeBreakpoint);

    this.setState({
      calendarDayFontSize:
        tiles && tiles[0] ? 0.18 * tiles[0].clientWidth + 'px' : '1em',
      isMobile: vw < largeBreakpoint,
    });
  };

  isDaySelected = (date, selectedDay) => {
    if (!selectedDay) {
      return false;
    }

    return moment(date).isSame(selectedDay, 'day');
  };

  onActiveDateChange = ({ activeStartDate }) => {
    const { setQueryInput } = this.props;

    setQueryInput(activeStartDate);
  };

  renderTileContent = ({ date }) => {
    const { loading, selectedDay, data, viewMode } = this.props;

    if (loading) {
      return null;
    }

    const isSelected = this.isDaySelected(date, selectedDay);

    const day = data
      ? data.find(day => {
          return moment(date).isSame(day.date, 'day');
        })
      : null;

    const events = day ? day.events : [];
    const earnEvents = day ? day.earnEvents : [];
    const fullEventsList = [...events, ...earnEvents];

    return (
      <DayTile
        isSelected={isSelected}
        hasEvents={fullEventsList.length > 0}
        events={events}
        viewMode={viewMode}
      />
    );
  };

  tileDisabled = ({ date }) => {
    const { disabled } = this.props;

    return disabled || moment(date).isBefore(moment().startOf('day'));
  };

  tileClassName = ({ date }) => {
    const { selectedWeek } = this.props;
    const { isMobile } = this.state;

    let className =
      isMobile && selectedWeek !== null && moment(date).week() === selectedWeek
        ? 'selectedWeek'
        : '';

    if (
      isMobile &&
      selectedWeek !== null &&
      moment(date).week() !== selectedWeek
    ) {
      className += ' disabled';
    }

    return className;
  };

  handleCloseClick = () => {
    const { setSelectedWeek, setSelectedDay } = this.props;

    setSelectedWeek(null);
    setSelectedDay(null);
  };

  handleScroll = event => {
    event.preventDefault();
    const element = event.target;

    if (element && element.scrollingElement) {
      if (element.scrollingElement.scrollTop >= 160 && !this.state.sticky) {
        this.setState({
          sticky: true,
        });
      } else if (element.scrollingElement.scrollTop < 80 && this.state.sticky) {
        this.setState({
          sticky: false,
        });
      }
    }
  };

  render() {
    const {
      onDatesChange,
      loading,
      activeStartDate,
      nextActiveStartDate,
      selectedWeek,
      hideCalendar,
      disabled,
      setTypeFilter,
      typeFilter,
      viewMode,
      selectedDay,
      onClickDay,
    } = this.props;

    const { calendarDayFontSize, isMobile, sticky } = this.state;

    // We can only be rendering six weeks on the calendar if this month
    // has 31 days and the first day of the month is Friday or Saturday,
    // or this month has 30 days and the first day of the month is Saturday.
    const firstDayOfMonth = moment(activeStartDate);
    const daysInMonth = firstDayOfMonth.daysInMonth();
    const isSunday = firstDayOfMonth.weekday() === 0;
    const mapSundayToSeven = isSunday ? 7 : firstDayOfMonth.weekday();
    const weekday = DAYS_OF_WEEK[mapSundayToSeven];
    const sixWeekMonth =
      (daysInMonth === 31 && (weekday === FRIDAY || weekday === SATURDAY)) ||
      (daysInMonth === 30 && weekday === SATURDAY);

    const calendarWidth =
      (this.calendarRef &&
        this.calendarRef.current &&
        this.calendarRef.current.clientWidth) ||
      0;

    const maxDate = moment()
      .add(1, 'year')
      .toDate();

    // Calculate the calendar's height based on tile height
    // and the number of weeks rendered.
    const numWeeks = sixWeekMonth ? 6 : 5;
    const extraHeaderHeightToBePrunedToGetToFiftyPixelsExactly = sixWeekMonth
      ? 4.0469
      : 4.0312;
    const calendarHeight =
      (calendarWidth || 0) * TILE_WIDTH_PERCENTAGE * numWeeks +
      CALENDAR_HEIGHT_PADDING -
      extraHeaderHeightToBePrunedToGetToFiftyPixelsExactly;

    const weekView =
      isMobile &&
      selectedWeek &&
      !(
        viewMode === UPCOMING_EVENTS_WITH_CALENDAR ||
        viewMode === MINI_CALENDAR ||
        viewMode === ENTERTAINMENT_CALENDAR ||
        viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS
      );

    return (
      <React.Fragment>
        <StyledDiv show={weekView}>
          <div>
            <CloseButton
              className='button trigger'
              onClick={this.handleCloseClick}
              show={weekView}
            >
              <FontAwesomeIcon icon='calendar' aria-label='close-calendar' />{' '}
              Month View
            </CloseButton>
          </div>
          <EventFilter
            id={'mobile'}
            setTypeFilter={setTypeFilter}
            typeFilter={typeFilter}
            show={weekView}
            isMobile={isMobile}
          />
        </StyledDiv>
        <ReactCalendarWrapper
          calendarDayFontSize={calendarDayFontSize}
          sixWeekMonth={sixWeekMonth}
          weekView={weekView}
          hideCalendar={hideCalendar}
          sticky={sticky}
          ref={this.calendarRef}
          tileHeight={
            calendarWidth
              ? calendarWidth * TILE_WIDTH_PERCENTAGE + 'px'
              : 'auto'
          }
          calendarHeight={calendarHeight}
          disabled={disabled}
          viewMode={viewMode}
        >
          {loading && (
            <SpinnerContainer>
              <FontAwesomeIcon icon='spinner' size='5x' spin />
            </SpinnerContainer>
          )}
          {isMobile !== null && (
            <>
              <ReactCalendar
                minDetail='month'
                maxDate={maxDate}
                value={selectedDay}
                onChange={onDatesChange}
                onClickDay={onClickDay}
                returnValue='range'
                formatMonthYear={(locale, date) =>
                  getFormatMonthYear(locale, date, weekView, selectedDay)
                }
                activeStartDate={activeStartDate}
                onActiveStartDateChange={
                  !disabled ? this.onActiveDateChange : null
                }
                calendarType='US'
                tileContent={
                  !loading && !disabled ? this.renderTileContent : null
                }
                tileDisabled={this.tileDisabled}
                tileClassName={weekView ? this.tileClassName : null}
              />
              {!(
                viewMode === UPCOMING_EVENTS_WITH_CALENDAR ||
                viewMode === MINI_CALENDAR ||
                viewMode === ENTERTAINMENT_CALENDAR ||
                viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS
              ) && (
                <ReactCalendar
                  minDetail='month'
                  onChange={onDatesChange}
                  maxDate={maxDate}
                  value={selectedDay}
                  onClickDay={onClickDay}
                  returnValue='range'
                  activeStartDate={nextActiveStartDate}
                  onActiveStartDateChange={
                    !disabled ? this.onActiveDateChange : null
                  }
                  calendarType='US'
                  tileContent={
                    !loading && !disabled ? this.renderTileContent : null
                  }
                  tileDisabled={this.tileDisabled}
                  tileClassName={weekView ? this.tileClassName : null}
                />
              )}
            </>
          )}
        </ReactCalendarWrapper>
      </React.Fragment>
    );
  }
}

Calendar.propTypes = propTypes;

export default Calendar;
