import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { ifProp, prop } from 'styled-tools';
import moment from 'moment';
import { getDateValues } from 'routes/HomePage/components/EventCard/dateLogic';
import ViewDetailsExpand from 'routes/HomePage/components/EventCard/ViewDetailsExpand';
import { eventShape } from 'utils/shapes';
import { ENTERTAINMENT_CALENDAR_WITH_DETAILS } from 'utils/constants';
import DefaultImage from 'images/default-event.png';
import EmployeesOnlyIconImage from 'images/employees-only-icon.png';

const propTypes = {
  event: eventShape,
  selectedDay: PropTypes.object,
  viewMode: PropTypes.string,
  featuredEvent: PropTypes.bool,
  isMobile: PropTypes.bool,
  documentRightEdge: PropTypes.number,
  property: PropTypes.string,
  earnPeriod: PropTypes.bool,
  inMobileApp: PropTypes.bool,
};

const EventDate = styled.time`
  ${({ theme }) => css`
    color: ${theme.colors.darkerGrey};
    font-size: 0.75rem;
    border-right: 1px solid ${theme.colors.borderGrey};
    flex: 1 0 3.125rem;
    max-width: 3.125rem;
    font-weight: 700;
    letter-spacing: 0.14rem;
    padding: 1rem 0.5rem;
    text-transform: uppercase;
    text-align: center;

    strong {
      font-family: 'Hurme Geometric Sans 1';
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      color: ${theme.colors.atmoreRed};
      display: block;
    }
  `}
`;

const EventInfo = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const StyledEventCard = styled.li`
  ${({ theme, hide }) => css`
    background-color: ${theme.colors.white};
    display: flex;
    display: ${hide ? 'none' : 'flex'};
    border-top: 1px solid ${theme.colors.borderGrey};
    border-bottom: 1px solid ${theme.colors.borderGrey};

    padding: 0;
    margin: 0;
    list-style: none;

    & + & {
      border-top: 0;
    }

    @media screen and (min-width: ${prop('theme.breakpoints.small')}) {
      ${EventDate} {
        flex: 1 0 10%;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    @media screen and (min-width: ${prop('theme.breakpoints.large')}) {
      border: 0;
      border-radius: 0.25rem;
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
      background-color: ${theme.colors.white};

      & + & {
        margin-top: 1.5rem;
      }
    }
  `}
`;

const ShortDescriptionHeader = styled.h4`
  font-family: 'proxima-nova', Arial, sans-serif;
  font-size: 1.125rem;
  color: ${props => props.theme.colors.atmoreRed};
  margin-bottom: 0.5rem;

  ${ifProp(
    { $viewMode: ENTERTAINMENT_CALENDAR_WITH_DETAILS },
    css`
      font-size: 1.25rem;
      margin-top: 0.5rem;
    `
  )}
`;

const ShortDescription = styled.div`
  font-size: 1rem;
  white-space: pre-wrap;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const EventHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const EmployeesOnlyIcon = styled.img`
  max-width: 3em;
  display: block;
`;

function EventCard({ event, viewMode, earnPeriod, inMobileApp, property }) {
  const eventName = event && event.name ? event.name : null;
  const eventShortDescriptionHeader = event && event.shortDescriptionHeader;
  const instanceDate = moment(event && event.instanceDate);
  const showDetails =
    viewMode && viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS;
  const imageToShow = event.imageUrl || event.thumbnailImageUrl || DefaultImage;
  const { date, month, day, dayOfWeek } = getDateValues(instanceDate);

  return (
    <>
      <StyledEventCard>
        <EventDate datetime={date}>
          {month} <strong>{day}</strong> {dayOfWeek}
        </EventDate>
        <EventInfo>
          <EventHeader>
            {eventName && <h1>{eventName}</h1>}
            {event.isEmployeeOnly && (
              <EmployeesOnlyIcon
                src={EmployeesOnlyIconImage}
                alt='Employees Only'
              />
            )}
          </EventHeader>
          {eventShortDescriptionHeader && !showDetails && (
            <ShortDescriptionHeader>
              {eventShortDescriptionHeader}
            </ShortDescriptionHeader>
          )}
          {event && event.shortDescription && !showDetails && (
            <ShortDescription>{event.shortDescription}</ShortDescription>
          )}
          {showDetails && imageToShow && (
            <StyledImage
              src={imageToShow}
              alt={event.imageAlt ? event.imageAlt : eventName}
            />
          )}
          {eventShortDescriptionHeader && showDetails && (
            <ShortDescriptionHeader $viewMode={viewMode}>
              {eventShortDescriptionHeader}
            </ShortDescriptionHeader>
          )}
          {showDetails && (
            <ViewDetailsExpand
              event={event}
              earnPeriod={earnPeriod}
              inMobileApp={inMobileApp}
              viewMode={viewMode}
            />
          )}
        </EventInfo>
      </StyledEventCard>
    </>
  );
}

EventCard.propTypes = propTypes;

export default EventCard;
