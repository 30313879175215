import React, { createContext, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export const USER_QUERY = gql`
  query MyUser {
    user {
      email
      userId
      displayName
      preferredLocation
      avatarUrl
      isWallBanned
      creationDate
      roles
    }
  }
`;

export const UserContext = createContext(null);

export function UserProvider({ children }) {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const token = params.token;

  const [user, setUser] = useState(null);

  useQuery(USER_QUERY, {
    skip: !token,
    onCompleted: data => {
      setUser(data.user);
    },
  });

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
