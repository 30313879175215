import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import earnEntryTodayImage from 'images/earn-entry-today.png';
import drawingDayImage from 'images/drawing-day.png';
import earnDrawTodayImage from 'images/earn-draw-today.png';

const EarnBanner = styled.p`
  ${({ theme }) => css`
    font-size: 0.75rem;
    color: ${theme.colors.atmoreRed};
    font-weight: 700;
    letter-spacing: 0.14rem;
    margin-bottom: 0;
    padding: 0.25rem 0.5rem;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    line-height: 1.25rem;
    float: left;

    img {
      max-height: 4rem;
      max-width: 100%;
    }

    strong {
      display: block;
      font-family: 'Knockout 48 A', 'Knockout 48 B', 'Arial Narrow', Arial,
        sans-serif;
      font-style: normal;
      font-size: 1.125rem;
      color: ${theme.colors.borderGrey};
      font-weight: 500;
    }

    @media screen and (max-width: 995px) {
      margin-left: 0;
    }

    @media screen and (min-width: ${theme.breakpoints.large}) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      float: none;
      margin-left: 1.5rem;
    }
  `}
  
  ${ifProp(
    'marginLeft',
    css`
      margin-left: 1rem;

      @media screen and (max-width: 995px) {
        margin-left: 1rem;
      }
    `
  )}
  
  ${ifProp(
    'mobileView',
    css`
      @media screen and (min-width: ${props =>
          props.theme.breakpoints.medium}) {
        display: none;
      }
    `
  )}

  ${ifProp(
    'listView',
    css`
      margin-bottom: 1rem;
      margin-top: -4.75rem;

      @media screen and (max-width: ${props => props.theme.breakpoints.large}) {
        display: none;
      }
    `
  )}

  ${ifProp(
    'tabletView',
    css`
      @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
        display: none;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    `
  )}
`;

function EarnToday({
  earnPeriod,
  mobileView,
  listView,
  tabletView,
  marginLeft,
  isDrawingDay,
  isInEarnPeriod,
}) {
  return (
    <>
      {earnPeriod && (
        <EarnBanner
          mobileView={mobileView}
          listView={listView}
          tabletView={tabletView}
          marginLeft={marginLeft}
        >
          {isInEarnPeriod && isDrawingDay ? (
            <img src={earnDrawTodayImage} alt='Earn and Draw Today' />
          ) : (
            <img src={earnEntryTodayImage} alt='Earn Entry Today' />
          )}
        </EarnBanner>
      )}
      {!earnPeriod && isDrawingDay && (
        <EarnBanner
          mobileView={mobileView}
          listView={listView}
          tabletView={tabletView}
          marginLeft={marginLeft}
        >
          <img src={drawingDayImage} alt='Drawing Day' />
        </EarnBanner>
      )}
    </>
  );
}

EarnToday.propTypes = {
  earnPeriod: PropTypes.bool,
  mobile: PropTypes.bool,
  tabletView: PropTypes.bool,
  listView: PropTypes.bool,
  isDrawingDay: PropTypes.bool,
  isInEarnPeriod: PropTypes.bool,
};

export default EarnToday;
