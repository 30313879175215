import ApolloClient from 'apollo-boost';
import queryString from 'query-string';
import uuidv4 from 'uuid/v4';
import { setRequestId } from 'utils/requestId';

export default function configureApollo(history) {
  const params = queryString.parse(history.location.search);
  const token = params.token;

  const requestId = uuidv4();

  setRequestId(requestId);

  const headers = {
    'X-WCC-Request-Id': requestId,
  };

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_API_URI,
    headers: headers,
  });

  return client;
}
