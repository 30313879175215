import PropTypes from 'prop-types';

export const eventCategoryShape = PropTypes.shape({
  displayName: PropTypes.string,
  eventCategoryId: PropTypes.number,
  lastModified: PropTypes.string,
  value: PropTypes.string,
});

export const recurringPatternShape = PropTypes.shape({
  dayOfMonth: PropTypes.number,
  daysOfWeek: PropTypes.arrayOf(PropTypes.number),
  maxNumberOfOccurrences: PropTypes.number,
  monthOfYear: PropTypes.number,
  recurringType: PropTypes.string,
  separationCount: PropTypes.number,
  weekOfMonth: PropTypes.number,
});

export const eventShape = PropTypes.shape({
  category: eventCategoryShape,
  createdDate: PropTypes.string,
  detailsUrl: PropTypes.string,
  earnPeriodEndDate: PropTypes.string,
  earnPeriodEndTime: PropTypes.string,
  earnPeriodDescription: PropTypes.string,
  earnPeriodImageUrl: PropTypes.string,
  earnPeriodRecurringPattern: recurringPatternShape,
  earnPeriodStartDate: PropTypes.string,
  earnPeriodStartTime: PropTypes.string,
  enabled: PropTypes.bool,
  endDate: PropTypes.string,
  endTime: PropTypes.string,
  eventId: PropTypes.number,
  imageAlt: PropTypes.string,
  imageUrl: PropTypes.string,
  isEarnPeriodRecurring: PropTypes.bool,
  isFullDayEvent: PropTypes.bool,
  isRecurring: PropTypes.bool,
  lastModified: PropTypes.string,
  name: PropTypes.string,
  occurrenceDetails: PropTypes.string,
  parentEventId: PropTypes.number,
  promotionTypes: PropTypes.arrayOf(PropTypes.string),
  property: PropTypes.string,
  recurringPattern: recurringPatternShape,
  shortDescription: PropTypes.string,
  startDate: PropTypes.string,
  startTime: PropTypes.string,
});

export const propertyShape = PropTypes.shape({
  propertyCode: PropTypes.string,
  displayName: PropTypes.string,
  enabled: PropTypes.bool,
});
