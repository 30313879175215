import queryString from 'query-string';
import {
  UPCOMING_EVENTS,
  UPCOMING_EVENTS_WITH_FEATURED,
  UPCOMING_EVENTS_WITH_CALENDAR,
  MINI_CALENDAR,
  FEATURED_EVENT,
  ENTERTAINMENT_CALENDAR,
  ENTERTAINMENT_CALENDAR_WITH_DETAILS,
  FULL_VIEW,
} from 'utils/constants';

const DEFAULT_CALENDAR_SIZE = 4;
const CALENDAR_SIZE_FOR_VIEW_MODE = {
  [MINI_CALENDAR]: 12,
  [ENTERTAINMENT_CALENDAR]: 9,
  [ENTERTAINMENT_CALENDAR_WITH_DETAILS]: 9,
};

export function isUpcomingEventsView(viewMode) {
  return (
    viewMode === UPCOMING_EVENTS ||
    viewMode === UPCOMING_EVENTS_WITH_FEATURED ||
    viewMode === UPCOMING_EVENTS_WITH_CALENDAR ||
    viewMode === FEATURED_EVENT
  );
}

export function isMiniCalendar(location = null) {
  const searchString = location ? location.search : window.location.search;
  const params = queryString.parse(searchString);

  return params.viewMode && params.viewMode === MINI_CALENDAR;
}

export function getCalendarSizeForViewMode(viewMode) {
  return CALENDAR_SIZE_FOR_VIEW_MODE[viewMode] || DEFAULT_CALENDAR_SIZE;
}

export function isFixedHeightViewMode(viewMode) {
  return [
    FULL_VIEW,
    ENTERTAINMENT_CALENDAR,
    ENTERTAINMENT_CALENDAR_WITH_DETAILS,
  ].includes(viewMode);
}
