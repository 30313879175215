import React from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html-react';

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'br'],
  allowedAttributes: {
    a: ['href'],
  },
  allowedIframeHostnames: ['www.youtube.com', 'vimeo.com'],
};

function sanitize(dirty, options) {
  return {
    __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
  };
}

const propTypes = {
  html: PropTypes.string.isRequired,
  options: PropTypes.object,
};

const defaultProps = {};

function SanitizeHtml({ html, options }) {
  return <span dangerouslySetInnerHTML={sanitize(html, options)} />;
}

SanitizeHtml.propTypes = propTypes;
SanitizeHtml.defaultProps = defaultProps;

export default SanitizeHtml;
