import moment from 'moment';

export function getDate({ selectedDay, startDate, endDate }) {
  if (selectedDay < startDate) {
    return startDate;
  } else if (endDate < selectedDay) {
    return endDate;
  } else {
    return selectedDay;
  }
}

export function getDateValues(passedDate) {
  const date = passedDate.format('YYYY-MM-DD');
  const month = moment(passedDate).format('MMM');
  const day = moment(passedDate).date();
  const dayOfWeek = moment(passedDate).format('ddd');
  const earnTodayDate = moment(passedDate).format('MM/DD/YYYY');
  return { date, month, day, dayOfWeek, earnTodayDate };
}

export function isDrawingDay(
  selectedDay,
  startDate,
  endDate,
  isRecurring,
  occurrenceDates
) {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);

  if (isRecurring && occurrenceDates) {
    const futureOccurrences = occurrenceDates.filter(
      date => !moment(date).isBefore(moment(selectedDay).startOf('day'))
    );

    return futureOccurrences.length > 0
      ? moment(futureOccurrences[0]).isSame(moment(selectedDay))
      : false;
  } else {
    if (startDate === endDate) {
      return moment(startDate).isSame(moment(selectedDay));
    } else if (endDateMoment.isSame(startDateMoment.clone().add(1, 'days'))) {
      return (
        startDateMoment.isSame(moment(selectedDay)) ||
        endDateMoment.isSame(moment(selectedDay))
      );
    } else {
      return endDateMoment.isSame(moment(selectedDay));
    }
  }
}
