import { createGlobalStyle } from 'styled-components/macro';
import { css } from 'styled-components';
import { isFixedHeightViewMode } from 'utils/viewModeUtils';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: ${props => (!props.viewMode ? '100%' : 'auto')}

    ${props =>
      isFixedHeightViewMode(props.viewMode) &&
      css`
        height: auto;
        @media screen and (min-width: ${props =>
            props.theme.breakpoints.large}) {
          height: 100%;
        }
      `}
  }

  html {
    -webkit-font-smoothing: antialiased;
  }
  
  body {
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.fontColor};
    background-color: ${props => props.theme.colors.appBackgroundGrey};
  }
  
  a {
    color: ${props => props.theme.a.color};
    font-weight: bold;
    text-decoration: underline;
    font-size: 0.9rem;
  }

  h5 {
    color: ${props => props.theme.headers.h5.color};
  }
  
  h5 > a {
    float: right;
    margin-top: 0.125em;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }
  
  @media screen and (max-width: 576px) {
    .modal-open .modal.error-modal .modal-dialog-centered {
      align-items: flex-start;
      padding-top: 1rem;
    }
    
    .modal.fade {
      opacity: 1;
    }
  }
  
  /* Ensure maintenance mode div is 100% height, otherwise we run into strange issues. */
  .maintenance-mode {
    height: 100%;
  }
  
  /* Internet Explorer 11 Specific Rules */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* Change placeholder text color */
    :-ms-input-placeholder {
      color: #757575 !important;
    }
  }

  /* global form styles */
  legend {
    font-family: ${props => props.theme.fontFamily};
    color: ${props =>
      props.error ? props.theme.colors.red : props.theme.forms.fieldset.color};
    font-weight: bold;
    font-size: 1em;
    margin-bottom: 0.25rem;

    &:after {
      content: ${props => (props.required ? "'*'" : '')};
      color: #f00;
      margin-left: 0.125em;
    }
  }

  fieldset {
    margin-top: 2.667em;

    &.form-group {
      margin-bottom: 1em;
    }
  }
  
  .form-group {
    margin-bottom: 0.5rem;
  }
  
  .form-group h5 {
    font-weight: bold;
  }
  
  .custom-control-label:before,
  .custom-control-label:after {
    width: 1.2em;
    height: 1.2em;
    top: 0.125rem;
  }
  
  .custom-control-label:before {
    border: 0.0625em solid black;
  }
  
  .custom-radio .custom-control-input:checked ~ .custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='4' fill='%23ba5cd6'/%3E%3C/svg%3E");
  }
  
  /* backdrop for modals */
  .modal-backdrop {
    background: rgba(255, 255, 255, 0.9);
  }

  /* Bootstrap Alert overrides */
  .alert {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    transition: all 1s ease;
    font-size: 1rem;
    border-left: 0.5rem solid;
    border-radius: 0.25rem;
    border-left-color: ${props => props.theme.alerts.borderColor};
    color: ${props => props.theme.alerts.fontColor};
    margin-top: 1rem;
    margin-bottom: 0;
    padding: 0.63rem;

    & > * + * {
      margin-left: 0.5rem;
    }

    .alert--icon {
      flex: 0 0 2rem;
      height: 1.5rem;
    }

    p.alert--copy {
      flex: 1 0 100%;
      color: ${props => props.theme.alerts.copyColor};
      margin: 0.5rem 0;
    }

    .alert--title {
      text-transform: uppercase;
      font-weight: 700;
    }

    .alert--link {
      flex: 1 0 100%;
      text-align: right;
      margin: 0;

      &::hover {
        color: ${props => props.theme.alerts.fontColor};
      }
    }

    .close {
      opacity: 1;
      font-size: 2.25rem;
      font-weight: normal;
      line-height: 1.5rem;
      text-shadow: 0;
      width: 2.75rem;
      height: 2.75rem;
    }

    &.alert-primary {
      background-color: ${props => props.theme.alerts.primary.backgroundColor};
    }

    &.alert-warning {
      background-color: ${props => props.theme.alerts.warning.backgroundColor};
    }

    &.alert-danger {
      background-color: ${props => props.theme.alerts.danger.backgroundColor};
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
      flex-wrap: nowrap;
      padding-right: 3rem;

      p.alert--copy {
        flex: 0 1 auto;
        margin: 0 0 0 0.5rem;
      }

      .alert--link {
        text-align: left;
        flex: 1 0 auto;
        margin: 0 0 0 0.5rem;
      }
    }
  }
  
  /* begin global styles from theme */
  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.headers.fontFamily};
    text-transform: ${props => props.theme.headers.textTransform};
    letter-spacing: ${props => props.theme.headers.letterSpacing};
    line-height: ${props => props.theme.headers.lineHeight};
    font-weight: ${props => props.theme.headers.fontWeight};
  }
  h1 {
    font-size: ${props => props.theme.headers.h1.fontSize};
    color: ${props => props.theme.headers.h1.color};
    letter-spacing: ${props => props.theme.headers.h1.letterSpacing};
  }
  h2 {
    font-size: ${props => props.theme.headers.h2.fontSize};
    color: ${props => props.theme.headers.h2.color};
  }
  h3 {
    font-size: ${props => props.theme.headers.h3.fontSize};
    color: ${props => props.theme.headers.h3.color};
  }
  h4 {
    font-size: ${props => props.theme.headers.h4.fontSize};
    font-family: ${props => props.theme.headers.h4.fontFamily};
    font-weight: ${props => props.theme.headers.h4.fontWeight};
    color: ${props => props.theme.headers.h4.color};
    letter-spacing: ${props => props.theme.headers.h4.letterSpacing};
    text-transform: ${props => props.theme.headers.h4.textTransform};
  }
  h5 {
    font-size: ${props => props.theme.headers.h5.fontSize};
    font-family: ${props => props.theme.headers.h5.fontFamily};
    font-weight: ${props => props.theme.headers.h5.fontWeight};
    color: ${props => props.theme.headers.h5.color};
    letter-spacing: ${props => props.theme.headers.h5.letterSpacing};
    text-transform: ${props => props.theme.headers.h5.textTransform};
    line-height: ${props => props.theme.headers.h5.lineHeight};
  }
  h6 {
    font-size: ${props => props.theme.headers.h6.fontSize};
    font-family: ${props => props.theme.headers.h6.fontFamily};
    font-weight: ${props => props.theme.headers.h6.fontWeight};
    color: ${props => props.theme.headers.h6.color};
    letter-spacing: ${props => props.theme.headers.h6.letterSpacing};
    text-transform: ${props => props.theme.headers.h6.textTransform};
    line-height: ${props => props.theme.headers.h6.lineHeight};
    font-style: ${props => props.theme.headers.h6.fontStyle};
  }
  blockquote {
    font-size: ${props => props.theme.blockquote.fontSize};
    background-color: ${props => props.theme.blockquote.backgroundColor};
    border-radius: ${props => props.theme.blockquote.borderRadius};
    color: ${props => props.theme.blockquote.color};
    font-style: ${props => props.theme.blockquote.fontStyle};
    line-height: ${props => props.theme.blockquote.lineHeight};
    padding: ${props => props.theme.blockquote.padding};
    position: ${props => props.theme.blockquote.position};
  }
  a {
    color: ${props => props.theme.a.color};
    font-size: ${props => props.theme.a.fontSize};
    font-weight: ${props => props.theme.a.fontWeight};
    text-decoration: ${props => props.theme.a.textDecoration};
  }
  svg {
    .icon {
      transform: ${props => props.theme.svg.icon.transform};
    }
  }
  ul, ol {
    display: ${props => props.theme.lists.display};
  }
  ul {
    list-style-type: ${props => props.theme.lists.ul.listStyleType};
    padding: ${props => props.theme.lists.ul.padding};
  }
  ol {
    list-style-type: ${props => props.theme.lists.ol.listStyleType};
    padding: ${props => props.theme.lists.ol.padding};
  }
  ol[class], ul[class] {
    list-style: ${props => props.theme.lists.withClassName.listStyle};
    padding: ${props => props.theme.lists.withClassName.padding};
  }
  li + li {
    margin-top: ${props => props.theme.lists.doubleLi.marginTop};
  }
  details[open] summary {
    margin-bottom: ${props => props.theme.detailsSummary.open.marginBottom};
  }
  details > summary {
    list-style: ${props => props.theme.detailsSummary.iconRemoval.listStyle};
  }
  details > summary::-webkit-details-marker {
    display: ${props => props.theme.detailsSummary.iconRemoval.display};
  }
  details summary {
    font-family: ${props => props.theme.detailsSummary.fontFamily};
    font-style: ${props => props.theme.detailsSummary.fontStyle};
    font-weight: ${props => props.theme.detailsSummary.fontWeight};
    font-size: ${props => props.theme.detailsSummary.fontSize};
    -webkit-appearance: ${props => props.theme.detailsSummary.appearance};
    -moz-appearance:${props => props.theme.detailsSummary.appearance};
    appearance: ${props => props.theme.detailsSummary.appearance};
    border: ${props => props.theme.detailsSummary.border};
    cursor: ${props => props.theme.detailsSummary.cursor};
    display: ${props => props.theme.detailsSummary.display};
    font-weight: ${props => props.theme.detailsSummary.fontWeight};
    letter-spacing: ${props => props.theme.detailsSummary.letterSpacing};
    max-width: ${props => props.theme.detailsSummary.maxWidth};
    text-align: ${props => props.theme.detailsSummary.textAlign};
    text-transform: ${props => props.theme.detailsSummary.textTransform};
    white-space: ${props => props.theme.detailsSummary.whiteSpace};
    border-radius: ${props => props.theme.detailsSummary.borderRadius};
    display: ${props => props.theme.detailsSummary.display};
    padding: ${props => props.theme.detailsSummary.padding};
    text-decoration: ${props => props.theme.detailsSummary.textDecoration};
    transition: ${props => props.theme.detailsSummary.transition};
    background-color: ${props => props.theme.detailsSummary.backgroundColor};
    border-color: ${props => props.theme.detailsSummary.borderColor};
    color: ${props => props.theme.detailsSummary.color};
    width: ${props => props.theme.detailsSummary.width};
  }
`;

export default GlobalStyle;
