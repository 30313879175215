import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaintenanceModeModal from '../MaintenanceModeModal';
import { getCredentialsSetting } from '../../utils/cookies';

const FETCH_OPTIONS = {
  credentials: getCredentialsSetting(),
  mode: 'cors',
  cache: 'no-cache',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

class MaintenanceMode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maintenanceMode: false,
    };
  }

  componentDidMount() {
    this.queryMaintenanceMode();
  }

  queryMaintenanceMode() {
    // GraphQL endpoint is disabled during maintenance mode, so fetch directly
    fetch(process.env.REACT_APP_API_URI + '/maintenance', FETCH_OPTIONS)
      .then(response => {

        if (response.ok && response.status === 200) {
          return response.json();
        }

        return Promise.reject();
      })
      .then(json => {
        this.setState({ maintenanceMode: json.enabled });
      });
  }

  render() {
    const { children } = this.props;
    const { maintenanceMode } = this.state;

    if (maintenanceMode) {
      return (
        <MaintenanceModeModal
            message={'Maintenance mode, please come back soon!'}
            isOpen={maintenanceMode}
        />);
    } else {
      return (<div className="maintenance-mode">{children}</div>);
    }
  }
}

MaintenanceMode.propTypes = {
  children: PropTypes.node,
};

export default MaintenanceMode;
