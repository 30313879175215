import styled from 'styled-components/macro';

const SpinnerContainer = styled.div`
  position: absolute;
  top: 2.4em;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .fa-spinner {
    color: ${props => props.theme.calendar.navigation.backgroundColor};
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
    top: 3em;
  }
`;

export default SpinnerContainer;
