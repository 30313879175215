import React from 'react';
import styled from 'styled-components/macro';

const FilterIconWrapper = styled.svg`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;

function FilterIcon({ id }) {
  return (
    <FilterIconWrapper>
      <svg
        viewBox='0 0 24 24'
        id={'icon-filter' + id}
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill='none' fillRule='evenodd'>
          <path
            d='M10 18h4v-2h-4zM3 6v2h18V6zm3 7h12v-2H6z'
            fill='currentColor'
            fillRule='nonzero'
          ></path>
          <path d='M0 0h24v24H0z'></path>
        </g>
      </svg>
    </FilterIconWrapper>
  );
}

export default FilterIcon;
