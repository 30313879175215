import React, { useCallback, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from 'reactstrap';
import styled, { css } from 'styled-components/macro';
import { prop } from 'styled-tools';
import _ from 'lodash';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import { Query } from '@apollo/react-components';
import { useQuery } from '@apollo/react-hooks';
import { ApolloConsumer } from '@apollo/client';
import gql from 'graphql-tag';
import queryString from 'query-string';
import {
  selectCalendarQueryInput,
  selectSelectedDay,
  selectStartDate,
  selectEndDate,
  selectSelectedDayEvents,
  selectSelectedDayEarnEvents,
  selectEventsData,
  selectIsMobile,
} from './selectors';
import { selectProperty } from 'routes/selectors';
import {
  setSelectedDay,
  setStartDate,
  setEndDate,
  setIsMobile,
  setCalendarQueryInput,
  setSelectedDayEvents,
  setSelectedDayEarnEvents,
  setEventsData,
} from './reducer';
import Calendar from './components/Calendar';
import CalendarCol from './components/styled/CalendarCol';
import EventsCol from './components/styled/EventsCol';
import EventList from './components/EventList';
import EventCard from './components/EventCard';
import EntertainmentEventCard from './components/EntertainmentEventCard';
import FeaturedEvent from './components/FeaturedEvent';
import LargestCol from 'components/LargestCol';
import MessageCard from './components/styled/MessageCard';
import {
  BONUS_GAME,
  COME_AND_GET,
  EARN_GET,
  ELECTRONIC_DRAWING,
  ENTERTAINMENT_EVENT,
  FOOD_CREDIT,
  FREE_PLAY,
  HOLIDAY_SHOPPING_DOLLARS,
  HOT_SEATS,
  PROMOTION_EVENT,
  SPECIAL_EVENT,
  TICKET_DRAWING,
  DEFAULT_VIEW_MODE,
  UPCOMING_EVENTS,
  UPCOMING_EVENTS_WITH_FEATURED,
  MINI_CALENDAR,
  UPCOMING_EVENTS_WITH_CALENDAR,
  FEATURED_EVENT,
  ENTERTAINMENT_CALENDAR,
  ENTERTAINMENT_CALENDAR_WITH_DETAILS,
  VENUE_NAME,
  CULINARY_STUDIO,
  ONLINE_TOURNAMENT,
  ONLINE_DRAWING,
} from 'utils/constants';
import {
  isUpcomingEventsView,
  isMiniCalendar,
  getCalendarSizeForViewMode,
} from 'utils/viewModeUtils';
import { UserContext } from 'contexts/UserContext';
import { canSeeEmployeeOnlyEvents } from 'utils/roles';

const propTypes = {
  showUnpublishedEvents: PropTypes.bool,
};
const defaultProps = {
  showUnpublishedEvents: false,
};

const QUERY_GET_CALENDAR = gql`
  query GetCalendar($input: GetCommunityEventsCalendar!) {
    communityEventsCalendar(input: $input) {
      calendarDays {
        date
        eventIds
      }
      earnDays {
        date
        eventIds
      }
      events {
        eventId
        name
        property
        category {
          eventCategoryId
          displayName
          value
          lastModified
        }
        isFullDayEvent
        isRecurring
        startDate
        endDate
        startTime
        endTime
        isEarnPeriodRecurring
        earnPeriodStartDate
        earnPeriodEndDate
        earnPeriodStartTime
        earnPeriodEndTime
        earnPeriodDescription
        earnPeriodImageUrl
        shortDescription
        imageUrl
        imageAlt
        detailsUrl
        parentEventId
        createdDate
        lastModified
        promotionTypes
        recurringPattern {
          recurringType
          separationCount
          maxNumberOfOccurrences
          daysOfWeek
          weekOfMonth
          dayOfMonth
          monthOfYear
        }
        earnPeriodRecurringPattern {
          recurringType
          separationCount
          maxNumberOfOccurrences
          daysOfWeek
          weekOfMonth
          dayOfMonth
          monthOfYear
        }
        occurrenceDetails
        enabled
        shortDescriptionHeader
        howToQualifyHeader
        featuredImageUrl
        thumbnailImageUrl
        promoPerks {
          id
          name
          value
        }
        versePerks {
          id
          name
          value
        }
        occurrenceDates
        venueId
        isEmployeeOnly
      }
    }
  }
`;

const QUERY_GET_CALENDAR_TYPES = gql`
  query GetCalendarTypes {
    calendarTypes {
      id
      name
      queryParameter
    }
  }
`;

const QUERY_GET_VENUES = gql`
  query GetVenues {
    venues {
      id
      propertyCode
      name
    }
  }
`;

const StyledHomePage = styled.div`
  height: 100%;

  & > .row {
    height: 100%;
  }
`;

const CalendarRow = styled(Row)`
  flex-direction: row;
  margin-left: 0;
  margin-right: 0;
  height: 100%;
  width: 100%;

  ${props =>
    props.viewMode &&
    (props.viewMode === MINI_CALENDAR ||
      props.viewMode === ENTERTAINMENT_CALENDAR ||
      props.viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS) &&
    css`
      width: 100%;
      margin: 0 auto;
      overflow: auto;
    `}

  ${props =>
    props.viewMode &&
    (props.viewMode === ENTERTAINMENT_CALENDAR ||
      props.viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS) &&
    css`
      @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
        overflow: hidden;
      }
    `}
`;

const MaxHeightRow = styled(Row)`
  height: 100%;
`;

const CalendarHeader = styled.div`
  background: ${prop('theme.eventCards.dayBannerBackground')};
  font-family: 'Knockout 48 A', 'Knockout 48 B', 'Arial Narrow', Arial,
    sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.14rem;
  text-transform: uppercase;
  color: ${prop('theme.colors.white')};
  padding: 1rem;
  line-height: 1.25;
  text-align: left;
  width: 100%;
`;

const EVENT_TYPE_SORT_PRIORITY = [
  PROMOTION_EVENT,
  SPECIAL_EVENT,
  ENTERTAINMENT_EVENT,
];

const EVENT_PROMOTION_SORT_PRIORITY = [
  TICKET_DRAWING,
  ELECTRONIC_DRAWING,
  HOT_SEATS,
  BONUS_GAME,
  EARN_GET,
  COME_AND_GET,
  FREE_PLAY,
  HOLIDAY_SHOPPING_DOLLARS,
  FOOD_CREDIT,
  ONLINE_TOURNAMENT,
  ONLINE_DRAWING,
];

function HomePage({ showUnpublishedEvents, location }) {
  const calendarQueryInput = useSelector(selectCalendarQueryInput);
  const selectedDay = useSelector(selectSelectedDay);
  const selectedProperty = useSelector(selectProperty);
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);
  const selectedDayEvents = useSelector(selectSelectedDayEvents);
  const selectedDayEarnEvents = useSelector(selectSelectedDayEarnEvents);
  const eventsData = useSelector(selectEventsData);
  const isMobile = useSelector(selectIsMobile);
  const [typeFilter, setTypeFilter] = useState([]);
  const dispatch = useDispatch();
  const eventListRef = useRef(null);
  const calendarTypes = useQuery(QUERY_GET_CALENDAR_TYPES);
  const venues = useQuery(QUERY_GET_VENUES);
  const user = useContext(UserContext);

  const searchString = location ? location.search : window.location.search;
  const params = queryString.parse(searchString);

  const handleTypeFilterChanged = e => {
    setTypeFilter(e);
    getSelectedDayEvents(eventsData, selectedDay, e);
  };

  const handleSetQueryInput = useCallback(
    activeStartDate => {
      const startDateMoment = moment(activeStartDate);
      const endDateMoment = startDateMoment
        .clone()
        .add(1, 'months')
        .endOf('month');
      const startDate = startDateMoment.format('YYYY-MM-DD');
      const endDate = endDateMoment.format('YYYY-MM-DD');

      dispatch(setCalendarQueryInput(activeStartDate));
      dispatch(setStartDate(startDate));
      dispatch(setEndDate(endDate));

      if (eventListRef && eventListRef.current) {
        eventListRef.current.scrollTo(0, 0);
      }
    },
    [dispatch]
  );

  const filterEventsByType = useCallback(
    (events, typeFilter) =>
      events.filter(
        event =>
          typeFilter.length === 0 ||
          (event.category && typeFilter.includes(event.category.value))
      ),
    []
  );

  const filterEventsByVenue = useCallback(
    (events, venueId) =>
      events.filter(
        event => venueId === undefined || event.venueId === venueId
      ),
    []
  );

  const getSelectedDayEvents = useCallback(
    (data, selectedDay, typeFilter) => {
      const days =
        (data &&
          data.communityEventsCalendar &&
          data.communityEventsCalendar.calendarDays) ||
        [];

      const earnDays =
        (data &&
          data.communityEventsCalendar &&
          data.communityEventsCalendar.earnDays) ||
        [];

      const events =
        (data &&
          data.communityEventsCalendar &&
          data.communityEventsCalendar.events) ||
        [];

      const combinedDays = days.map(day => {
        return {
          ...day,
          events: day.eventIds
            .map(eventId => _.find(events, { eventId }) || null)
            .filter(event => event !== null),
        };
      });

      const selectedCalendarDay = selectedDay
        ? _.find(combinedDays, day => moment(day.date).isSame(selectedDay))
        : null;

      const selectedEarnDay = selectedDay
        ? _.find(earnDays, day => moment(day.date).isSame(selectedDay))
        : null;
      const selectedDayEarnEvents = filterEventsByType(
        selectedEarnDay
          ? selectedEarnDay.eventIds
              .map(eventId => _.find(events, { eventId }) || null)
              .filter(event => event !== null)
              .map(event => ({
                ...event,
                instanceDate: selectedEarnDay.date,
              }))
          : [],
        typeFilter
      );

      const shownEvents = filterEventsByType(
        selectedDay
          ? (selectedCalendarDay &&
              selectedCalendarDay.events.map(event => ({
                ...event,
                instanceDate: selectedCalendarDay.date,
              }))) ||
              []
          : uniqBy(
              _.flatten(
                combinedDays
                  .filter(day =>
                    moment(day.date).isSameOrAfter(moment(), 'day')
                  )
                  .map(day =>
                    day.events
                      .map(event => ({
                        ...event,
                        instanceDate: day.date,
                      }))
                      .sort((ev, ev2) => {
                        /* Sort events(day) by ascending using start-time  */
                        if (
                          ev &&
                          ev.length !== 0 &&
                          ev2 &&
                          ev2.length !== 0 &&
                          day.date
                        ) {
                          const startTime =
                            ev && ev.startTime ? ev.startTime : null;
                          const startTime2 =
                            ev2 && ev2.startTime ? ev2.startTime : null;
                          const eventTime = startTime
                            ? Date.parse(ev.instanceDate + ' ' + startTime)
                            : Date.parse(ev.instanceDate);
                          const eventTime2 = startTime2
                            ? Date.parse(ev2.instanceDate + ' ' + startTime2)
                            : Date.parse(ev2.instanceDate);

                          return eventTime > eventTime2
                            ? 1
                            : eventTime < eventTime2
                            ? -1
                            : 0;
                        }
                        return 0;
                      })
                  )
              ),
              'eventId'
            ),
        typeFilter
      );

      const filteredShownEvents = filterEventsByVenue(
        shownEvents,
        queryInput.venueId
      );

      if (selectedDay || isUpcomingEventsView(params.viewMode)) {
        dispatch(setSelectedDayEvents(filteredShownEvents));
        dispatch(setSelectedDayEarnEvents(selectedDayEarnEvents));
      }
    },
    [dispatch, filterEventsByType, params.viewMode]
  );

  const handleClickDay = useCallback(
    date => {
      dispatch(setSelectedDay(date));
      getSelectedDayEvents(
        eventsData,
        moment(selectedDay).isSame(date)
          ? new Date().setHours(0, 0, 0, 0)
          : date,
        typeFilter
      );

      if (eventListRef && eventListRef.current) {
        eventListRef.current.scrollTo(0, 0);
      }
    },
    [dispatch, eventsData, selectedDay, getSelectedDayEvents, typeFilter]
  );

  const handleSetIsMobile = useCallback(
    isMobile => dispatch(setIsMobile(isMobile)),
    [dispatch]
  );

  const [selectedWeek, setSelectedWeek] = useState(null);
  const propertiesCount = 1;

  const onDatesChange = ([date]) => {
    const dateMoment = moment(date);
    setSelectedWeek(dateMoment.week());
  };

  // For all Upcoming Events view except the calendar, startDate is today.
  // In all other cases, return the startDate from props.
  const getQueryStartDate = viewMode => {
    return isUpcomingEventsView(params.viewMode) &&
      params.viewMode !== UPCOMING_EVENTS_WITH_CALENDAR
      ? moment().format('YYYY-MM-DD')
      : startDate;
  };

  // For viewMode UPCOMING_EVENTS_WITH_CALENDAR, end date should be
  // startDate + 30 days.
  // For all other Upcoming Events views, it will be the end of the
  // current month selected.
  // Otherwise, we use endDate from props.
  const getQueryEndDate = viewMode => {
    if (
      isUpcomingEventsView(params.viewMode) &&
      params.viewMode !== UPCOMING_EVENTS_WITH_CALENDAR
    ) {
      return moment()
        .add(30, 'days')
        .format('YYYY-MM-DD');
    } else if (params.viewMode === UPCOMING_EVENTS_WITH_CALENDAR) {
      return moment(startDate)
        .endOf('month')
        .format('YYYY-MM-DD');
    } else {
      return endDate;
    }
  };

  const queryInput = {
    property: selectedProperty,
    startDate: getQueryStartDate(params.viewMode),
    endDate: getQueryEndDate(params.viewMode),
    showUnpublishedEvents,
    showEmployeeOnlyEvents: user && canSeeEmployeeOnlyEvents(user),
  };

  const sortEvents = events => {
    // eslint-disable-next-line
    return events.sort((ev, ev2) => {
      /* Sort events in day by ascending using start-time  */
      if (ev && ev.length !== 0 && ev2 && ev2.length !== 0) {
        // If events are different promotion types, sort by those. Otherwise, sort by date.
        const eventTypeA = ev.category && ev.category.value;
        const eventTypeB = ev2.category && ev2.category.value;

        if (eventTypeA !== eventTypeB) {
          // Different event types, sort by those.
          const indexA = EVENT_TYPE_SORT_PRIORITY.indexOf(eventTypeA);
          const indexB = EVENT_TYPE_SORT_PRIORITY.indexOf(eventTypeB);

          return indexA - indexB;
        }

        if (eventTypeA === PROMOTION_EVENT) {
          // Sort by promotion types.
          let lowestIndexA =
            ev.promotionTypes &&
            Math.min(
              ...ev.promotionTypes
                .map(type => EVENT_PROMOTION_SORT_PRIORITY.indexOf(type))
                .filter(index => index > -1)
            );

          let lowestIndexB =
            ev2.promotionTypes &&
            Math.min(
              ...ev2.promotionTypes
                .map(type => EVENT_PROMOTION_SORT_PRIORITY.indexOf(type))
                .filter(index => index > -1)
            );

          if (lowestIndexA === null) {
            lowestIndexA = 99;
          }

          if (lowestIndexB === null) {
            lowestIndexB = 99;
          }

          return lowestIndexA - lowestIndexB;
        }

        const startTime = ev && ev.startTime ? ev.startTime : null;
        const startTime2 = ev2 && ev2.startTime ? ev2.startTime : null;
        const eventTime = startTime
          ? Date.parse(ev.instanceDate + ' ' + startTime)
          : Date.parse(ev.instanceDate);
        const eventTime2 = startTime2
          ? Date.parse(ev2.instanceDate + ' ' + startTime2)
          : Date.parse(ev2.instanceDate);

        return eventTime > eventTime2 ? 1 : eventTime < eventTime2 ? -1 : 0;
      }
    });
  };

  const onQueryCompleted = data => {
    dispatch(setEventsData(data));
    getSelectedDayEvents(
      data,
      params.viewMode !== UPCOMING_EVENTS &&
        params.viewMode !== UPCOMING_EVENTS_WITH_FEATURED &&
        params.viewMode !== FEATURED_EVENT
        ? selectedDay
        : null,
      typeFilter
    );
  };

  const getCalendarDaysWithEvents = data => {
    const days =
      (data &&
        data.communityEventsCalendar &&
        data.communityEventsCalendar.calendarDays) ||
      [];

    const events =
      (data &&
        data.communityEventsCalendar &&
        data.communityEventsCalendar.events) ||
      [];
    const earnDays =
      (data &&
        data.communityEventsCalendar &&
        data.communityEventsCalendar.earnDays) ||
      [];

    // For days with earnable events, set those eventIds as earnIds,
    // and set eventIds equal to an empty array. This way we can
    // process the array of days together, but separate the occuring
    // events from the earnable events.
    const earnDaysWithIds = earnDays.map(earnDay => ({
      ...earnDay,
      earnIds: earnDay.eventIds,
      eventIds: [],
    }));

    return [...days, ...earnDaysWithIds].map(day => {
      return {
        ...day,
        events: day.eventIds
          .map(eventId => _.find(events, { eventId }) || null)
          .filter(event => event !== null),
        earnEvents: (day.earnIds || [])
          .map(eventId => find(events, { eventId }) || null)
          .filter(event => event !== null),
      };
    });
  };

  const miniCalendar = !!isMiniCalendar();
  const documentElement = document.getElementById('root');
  const documentRect = documentElement && documentElement.getClientRects()[0];
  const documentRightEdge = documentRect
    ? documentRect.width + documentRect.x
    : 0;

  // Combine events happening today and events that can be earned today,
  // get a unique list by eventId, and then sort the array.
  const combinedEvents = sortEvents(
    uniqBy([...selectedDayEarnEvents, ...selectedDayEvents], 'eventId')
  );

  // If eventType is passed in, this is passed to the query in order
  // limit the types of events returned
  if (params.eventType && calendarTypes.data) {
    const calendarType =
      calendarTypes &&
      find(
        calendarTypes.data.calendarTypes,
        calendarType => calendarType.queryParameter === params.eventType
      );

    if (calendarType) {
      queryInput.calendarTypeId = calendarType.id;
    }
  }

  // Limit events by the venue
  if (params.venue && venues.data) {
    const venue =
      venues &&
      find(
        venues.data.venues,
        venue =>
          venue.name === VENUE_NAME[params.venue] &&
          venue.propertyCode === selectedProperty
      );

    if (venue) {
      queryInput.venueId = venue.id;
    }
  }

  let previousMonthCached = null;

  // Skip the calendar query if queryInput is no set,
  // or if the venue param is used, but no venue was found to match.
  const skipCalendarQuery =
    !queryInput || (params.venue !== undefined && !queryInput.venueId);

  return (
    <StyledHomePage>
      <MaxHeightRow>
        <CalendarRow viewMode={params.viewMode || DEFAULT_VIEW_MODE}>
          <ApolloConsumer>
            {client => {
              const startMonth = moment(queryInput.startDate).subtract(
                1,
                'month'
              );
              const endMonth = moment(queryInput.endDate).subtract(1, 'month');
              const previousMonthStart = startMonth.format('YYYY-MM-DD');
              const previousMonthEnd = endMonth.format('YYYY-MM-DD');
              const cacheInput = Object.assign({}, queryInput, {
                startDate: previousMonthStart,
                endDate: previousMonthEnd,
              });

              try {
                previousMonthCached = client.readQuery({
                  query: QUERY_GET_CALENDAR,
                  variables: {
                    input: cacheInput,
                  },
                });
              } catch (e) {
                previousMonthCached = null;
              }
              return null;
            }}
          </ApolloConsumer>
          <Query
            query={QUERY_GET_CALENDAR}
            variables={{ input: queryInput }}
            skip={skipCalendarQuery}
            onCompleted={onQueryCompleted}
            notifyOnNetworkStatusChange
            fetchPolicy='network-only'
          >
            {({ loading, error, data, refetch }) => {
              const combinedDays = getCalendarDaysWithEvents(data);
              const previousCombinedDays = previousMonthCached
                ? getCalendarDaysWithEvents(previousMonthCached)
                : [];

              return (
                <>
                  <>
                    {params.viewMode &&
                      (params.viewMode === ENTERTAINMENT_CALENDAR ||
                        params.viewMode ===
                          ENTERTAINMENT_CALENDAR_WITH_DETAILS) && (
                        <CalendarHeader>
                          {params.venue && params.venue === CULINARY_STUDIO
                            ? 'Culinary Studio'
                            : 'Entertainment Calendar'}
                        </CalendarHeader>
                      )}
                  </>
                  {(!params.viewMode ||
                    (params.viewMode === MINI_CALENDAR ||
                      params.viewMode === ENTERTAINMENT_CALENDAR ||
                      params.viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS ||
                      params.viewMode === UPCOMING_EVENTS_WITH_CALENDAR)) && (
                    <CalendarCol
                      sm='12'
                      lg={getCalendarSizeForViewMode(params.viewMode)}
                      selectedWeek={selectedWeek}
                      $viewMode={params.viewMode}
                    >
                      <Calendar
                        selectedDay={selectedDay || new Date()}
                        onDatesChange={onDatesChange}
                        onClickDay={handleClickDay}
                        setQueryInput={handleSetQueryInput}
                        queryInput={calendarQueryInput}
                        data={[...previousCombinedDays, ...combinedDays]}
                        loading={loading}
                        error={error}
                        property={selectedProperty}
                        activeStartDate={moment(
                          calendarQueryInput.startDate
                        ).toDate()}
                        nextActiveStartDate={moment(
                          calendarQueryInput.startDate
                        )
                          .add(1, 'months')
                          .toDate()}
                        selectedWeek={selectedWeek}
                        setSelectedWeek={setSelectedWeek}
                        setSelectedDay={handleClickDay}
                        handleSetIsMobile={handleSetIsMobile}
                        setTypeFilter={handleTypeFilterChanged}
                        typeFilter={typeFilter}
                        viewMode={params.viewMode}
                      />
                      {(params.viewMode === UPCOMING_EVENTS_WITH_FEATURED ||
                        params.viewMode === FEATURED_EVENT) &&
                        !!params.eventType && (
                          <Query
                            query={QUERY_GET_CALENDAR_TYPES}
                            notifyOnNetworkStatusChange
                            fetchPolicy='network-only'
                          >
                            {({ data }) => {
                              return (
                                <FeaturedEvent
                                  selectedDay={selectedDay}
                                  eventType={params.eventType}
                                  calendarTypes={data.calendarTypes}
                                />
                              );
                            }}
                          </Query>
                        )}
                    </CalendarCol>
                  )}
                  {!miniCalendar && (
                    <EventsCol
                      sm='12'
                      lg={
                        params.viewMode === ENTERTAINMENT_CALENDAR ||
                        params.viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS
                          ? 3
                          : 8
                      }
                      $isUpcomingEvents={isUpcomingEventsView(params.viewMode)}
                      $viewMode={params.viewMode}
                      $selectedWeek={selectedWeek && isMobile}
                    >
                      <Row selectedWeek={selectedWeek}>
                        <LargestCol xs='12'>
                          {params.viewMode !== FEATURED_EVENT && (
                            <EventList
                              selectedDate={
                                selectedDay
                                  ? selectedDay
                                  : new Date().toString()
                              }
                              handleClickBack={handleClickDay}
                              eventListRef={eventListRef}
                              setTypeFilter={handleTypeFilterChanged}
                              typeFilter={typeFilter}
                              viewMode={params.viewMode || DEFAULT_VIEW_MODE}
                            >
                              {combinedEvents.length > 0 &&
                                (showUnpublishedEvents ||
                                  propertiesCount > 0) && (
                                  <>
                                    {combinedEvents.map((event, i) => (
                                      <>
                                        {params.viewMode ===
                                          ENTERTAINMENT_CALENDAR ||
                                        params.viewMode ===
                                          ENTERTAINMENT_CALENDAR_WITH_DETAILS ? (
                                          <EntertainmentEventCard
                                            key={`event-card-${i}`}
                                            selectedDay={moment(
                                              new Date(selectedDay)
                                            )}
                                            event={event}
                                            property={selectedProperty}
                                            viewMode={params.viewMode}
                                            earnPeriod={
                                              !!find(selectedDayEarnEvents, {
                                                eventId: event.eventId,
                                              })
                                            }
                                            inMobileApp={
                                              params.mobileapp === 'true'
                                            }
                                          />
                                        ) : (
                                          <EventCard
                                            key={`event-card-${i}`}
                                            selectedDay={moment(
                                              new Date(selectedDay)
                                            )}
                                            event={event}
                                            viewMode={
                                              params.viewMode ||
                                              DEFAULT_VIEW_MODE
                                            }
                                            isMobile={isMobile}
                                            documentRightEdge={
                                              documentRightEdge
                                            }
                                            earnPeriod={
                                              !!find(selectedDayEarnEvents, {
                                                eventId: event.eventId,
                                              })
                                            }
                                            inMobileApp={
                                              params.mobileapp === 'true'
                                            }
                                          />
                                        )}
                                      </>
                                    ))}
                                  </>
                                )}
                              {!loading &&
                                selectedDayEvents.length === 0 &&
                                selectedDayEarnEvents.length === 0 && (
                                  <MessageCard
                                    isUpcomingEvents={isUpcomingEventsView(
                                      params.viewMode
                                    )}
                                    viewMode={params.viewMode}
                                  >
                                    <h3>No Events Scheduled</h3>
                                    <p>Please check back soon.</p>
                                  </MessageCard>
                                )}
                            </EventList>
                          )}
                          {(params.viewMode === UPCOMING_EVENTS_WITH_FEATURED ||
                            params.viewMode === FEATURED_EVENT) &&
                            !!params.eventType && (
                              <FeaturedEvent
                                selectedDay={selectedDay}
                                eventType={params.eventType}
                                calendarTypes={
                                  calendarTypes && calendarTypes.data
                                    ? calendarTypes.data.calendarTypes
                                    : []
                                }
                              />
                            )}
                        </LargestCol>
                      </Row>
                    </EventsCol>
                  )}
                </>
              );
            }}
          </Query>
        </CalendarRow>
      </MaxHeightRow>
    </StyledHomePage>
  );
}

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default HomePage;
