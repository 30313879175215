import { createSelector } from 'reselect';

const rootSelector = state => state.app.calendar;

export const selectCalendarQueryInput = createSelector(
  rootSelector,
  subState => subState.calendarQueryInput
);

export const selectSelectedDay = createSelector(
  rootSelector,
  subState => subState.selectedDay
);

export const selectStartDate = createSelector(
  rootSelector,
  subState => subState.startDate
);

export const selectEndDate = createSelector(
  rootSelector,
  subState => subState.endDate
);

export const selectIsMobile = createSelector(
  rootSelector,
  subState => subState.isMobile
);

export const selectSelectedDayEvents = createSelector(
  rootSelector,
  subState => subState.selectedDayEvents
);

export const selectSelectedDayEarnEvents = createSelector(
  rootSelector,
  subState => subState.selectedDayEarnEvents
);

export const selectEventsData = createSelector(
  rootSelector,
  subState => subState.eventsData
);
