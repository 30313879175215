import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/macro';
import gql from 'graphql-tag';
import filter from 'lodash/filter';
import PropertyItem from './PropertyItem';
import { Query } from '@apollo/react-components';

export const StyledPropertySelect = styled.div`
  position: relative;
  z-index: 10;
  margin-bottom: 0;

  .unselected-items {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 100vh;
    overflow: auto;
    }
  }
`;

const StyledUnselectedItems = styled.div`
  &.hidden {
    display: none;
  }
`;

const QUERY_GET_PROPERTIES = gql`
  query properties {
    communityEventProperties {
      properties {
        propertyCode
        displayName
        enabled
        sortOrder
        lastModified
      }
    }
  }
`;

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  theme: PropTypes.object,
  showDisabledProperties: PropTypes.bool,
  onPropertiesCount: PropTypes.func,
};

class PropertySelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      noDisplay: true,
    };
  }

  setNoDisplay = isDisplay => {
    this.setState({ noDisplay: isDisplay });
  };

  toggleOpen = () => {
    this.setState({ open: !this.state.open });

    /* Set timeout for .15 sec to keep animation before hide item. */
    if (this.state.open) {
      setTimeout(() => this.setNoDisplay(true), 150);
    } else {
      this.setNoDisplay(false);
    }
  };

  selectProperty = property => {
    const { onChange } = this.props;

    onChange({ value: property, label: property });

    this.toggleOpen();
  };

  setPropertiesCount = count => {
    const { onPropertiesCount } = this.props;
    onPropertiesCount(count);
  };

  render() {
    const { value, theme, showDisabledProperties } = this.props;
    const { open, noDisplay } = this.state;

    return (
      <Query query={QUERY_GET_PROPERTIES}>
        {({ loading, error, data }) => {
          if (loading) {
            return null;
          }

          const items =
            data && data.communityEventProperties
              ? data.communityEventProperties.properties
                  .filter(
                    p =>
                      p.enabled || value === p.value || showDisabledProperties
                  )
                  .map(property => ({
                    value: property.propertyCode,
                    label: property.displayName,
                  }))
              : [];

          this.setPropertiesCount(items.length);

          if (items.length === 0) {
            return null;
          }

          const selectedItem = filter(items, item => item.value === value);
          const unselectedItems = filter(items, item => item.value !== value);
          const topItem =
            selectedItem && selectedItem.length > 0
              ? selectedItem[0]
              : items[0];

          return (
            <StyledPropertySelect>
              <PropertyItem
                property={topItem}
                value={topItem.value}
                isSelected={value || value === topItem.value}
                onClick={this.selectProperty}
                isOpen={true}
              />
              <StyledUnselectedItems
                className={`unselected-items ${noDisplay ? 'hidden' : ''}`}
                role='listbox'
              >
                {unselectedItems &&
                  unselectedItems.map((item, ind) => (
                    <PropertyItem
                      key={item.value}
                      property={item}
                      value={item.value}
                      href={
                        theme.propertySelector.linkToPropertySite
                          ? item.url
                          : undefined
                      }
                      isSelected={value === item.value}
                      onClick={
                        theme.propertySelector.linkToPropertySite && item.url
                          ? undefined
                          : this.selectProperty
                      }
                      isOpen={open}
                      timeouts={ind * 150}
                    />
                  ))}
              </StyledUnselectedItems>
            </StyledPropertySelect>
          );
        }}
      </Query>
    );
  }
}

PropertySelect.propTypes = propTypes;

export default withTheme(PropertySelect);
