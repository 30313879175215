import moment from 'moment';

const monthStartMoment = moment().startOf('month');
const monthEndMoment = monthStartMoment
  .clone()
  .add(1, 'months')
  .endOf('month');

const monthStart = monthStartMoment.format('YYYY-MM-DD');
const monthEnd = monthEndMoment.format('YYYY-MM-DD');

const today = new Date();
today.setHours(0, 0, 0, 0);

// The initial state of the App
const initialState = {
  calendarQueryInput: {
    startDate: monthStart,
    endDate: monthEnd,
  },
  selectedDay: today,
  startDate: monthStart,
  endDate: monthEnd,
  isMobile: false,
  selectedDayEvents: [],
  selectedDayEarnEvents: [],
  eventsData: null,
};

// Actions
const SET_CALENDAR_QUERY_INPUT = 'app/Calendar/SET_CALENDAR_QUERY_INPUT';
const SET_SELECTED_DAY = 'app/Calendar/SET_SELECTED_DAY';
const SET_START_DATE = 'app/Calendar/SET_START_DATE';
const SET_END_DATE = 'app/Calendar/SET_END_DATE';
const SET_IS_MOBILE = 'app/Calendar/SET_IS_MOBILE';
const SET_SELECTED_DAY_EVENTS = 'app/Calendar/SET_SELECTED_DAY_EVENTS';
const SET_SELECTED_DAY_EARN_EVENTS =
  'app/Calendar/SET_SELECTED_DAY_EARN_EVENTS';
const SET_EVENTS_DATA = 'app/Calendar/SET_EVENTS_DATA';

// Action Handlers
const ACTION_HANDLERS = {
  [SET_CALENDAR_QUERY_INPUT]: (state, action) => {
    let calendarQueryInput = null;

    if (action.payload.currentMonthStart) {
      const monthStartMoment = moment(action.payload.currentMonthStart);
      const monthEndMoment = monthStartMoment.clone().endOf('month');

      const monthStart = monthStartMoment.format('YYYY-MM-DD');
      const monthEnd = monthEndMoment.format('YYYY-MM-DD');

      calendarQueryInput = Object.assign({}, state.calendarQueryInput, {
        startDate: monthStart,
        endDate: monthEnd,
      });
    }

    return Object.assign({}, state, {
      calendarQueryInput,
    });
  },
  [SET_SELECTED_DAY]: (state, { payload: { date } }) => {
    const selectedDay = state.selectedDay;

    if (moment(selectedDay).isSame(date)) {
      return Object.assign({}, state, {
        selectedDay: null,
      });
    }

    return Object.assign({}, state, {
      selectedDay: date,
    });
  },
  [SET_START_DATE]: (state, { payload: { startDate } }) =>
    Object.assign({}, state, { startDate }),
  [SET_END_DATE]: (state, { payload: { endDate } }) =>
    Object.assign({}, state, { endDate }),
  [SET_IS_MOBILE]: (state, { payload: { isMobile } }) =>
    Object.assign({}, state, { isMobile }),
  [SET_SELECTED_DAY_EVENTS]: (state, { payload: { selectedDayEvents } }) =>
    Object.assign({}, state, { selectedDayEvents }),
  [SET_SELECTED_DAY_EARN_EVENTS]: (
    state,
    { payload: { selectedDayEarnEvents } }
  ) => Object.assign({}, state, { selectedDayEarnEvents }),
  [SET_EVENTS_DATA]: (state, { payload: { eventsData } }) =>
    Object.assign({}, state, { eventsData }),
};

// Action Creators
export function setCalendarQueryInput(currentMonthStart) {
  return {
    type: SET_CALENDAR_QUERY_INPUT,
    payload: { currentMonthStart },
  };
}

export function setSelectedDay(date) {
  return {
    type: SET_SELECTED_DAY,
    payload: { date },
  };
}

export function setStartDate(startDate) {
  return {
    type: SET_START_DATE,
    payload: { startDate },
  };
}

export function setEndDate(endDate) {
  return {
    type: SET_END_DATE,
    payload: { endDate },
  };
}

export function setIsMobile(isMobile) {
  return {
    type: SET_IS_MOBILE,
    payload: { isMobile },
  };
}

export function setSelectedDayEvents(selectedDayEvents) {
  return {
    type: SET_SELECTED_DAY_EVENTS,
    payload: { selectedDayEvents },
  };
}

export function setSelectedDayEarnEvents(selectedDayEarnEvents) {
  return {
    type: SET_SELECTED_DAY_EARN_EVENTS,
    payload: { selectedDayEarnEvents },
  };
}

export function setEventsData(eventsData) {
  return {
    type: SET_EVENTS_DATA,
    payload: { eventsData },
  };
}

// Reducer
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

export default reducer;
