import styled, { css } from 'styled-components/macro';
import { ifProp, switchProp } from 'styled-tools';
import LargestCol from 'components/LargestCol';
import { StyledPropertySelect } from '../PropertySelect';
import {
  FEATURED_EVENT,
  UPCOMING_EVENTS,
  UPCOMING_EVENTS_WITH_FEATURED,
  ENTERTAINMENT_CALENDAR,
  ENTERTAINMENT_CALENDAR_WITH_DETAILS,
} from 'utils/constants';

const EventsCol = styled(LargestCol)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  overflow-y: auto;

  /*
  * I don't know why, but this only fixes the Event List scroll
  * issue in Safari v13 if flex-basis is omitted.
  */
  flex: 1 0 0%;

  ${switchProp('$viewMode', {
    [UPCOMING_EVENTS]: css`
      flex-basis: 100%;
      max-width: 100%;
    `,
    [UPCOMING_EVENTS_WITH_FEATURED]: css`
      flex-basis: 100%;
      max-width: 100%;
    `,
    [FEATURED_EVENT]: css`
      flex-basis: 100%;
      max-width: 100%;
    `,
  })}

  & > .row {
    height: 100%;

    & > *[class*='col-'] {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0;

      ${ifProp(
        '$isUpcomingEvents',
        css`
          flex-direction: row;
        `
      )}
    }
  }

  & > div {
    flex: 1 0 auto;

    &:first-child {
      flex: 0 1 auto;
    }
  }

  div[class*='col-'].offers-form-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  ${StyledPropertySelect} {
    display: none;
  }

  div.column-header {
    display: none;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
    h5 {
      font-size: 1.25em;
    }

    div.column-header {
      display: block;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.medium}) {
    .rewards-note {
      font-size: 0.8em;
      margin-bottom: 0.5rem;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    margin-top: 0;

    ${StyledPropertySelect} {
      display: block;
    }
  }

  ${ifProp(
    '$selectedWeek',
    css`
      margin-top: 0.5rem;
    `
  )}

  /* This breakpoint accounts for padding added to the iframe on property sites */
  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;

    ${ifProp(
      { $viewMode: ENTERTAINMENT_CALENDAR },
      css`
        flex-basis: 30%;
        max-width: 30%;
        height: 100%;
      `
    )}
    
    ${ifProp(
      { $viewMode: ENTERTAINMENT_CALENDAR_WITH_DETAILS },
      css`
        flex-basis: 30%;
        max-width: 30%;
        height: 100%;
      `
    )}
  }

  @media screen and (min-width: ${props =>
    props.theme.breakpoints.extraLarge}) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;

    ${ifProp(
      { $viewMode: ENTERTAINMENT_CALENDAR },
      css`
        flex-basis: 25%;
        max-width: 25%;
        height: 100%;
      `
    )}
    
    ${ifProp(
      { $viewMode: ENTERTAINMENT_CALENDAR_WITH_DETAILS },
      css`
        flex-basis: 25%;
        max-width: 25%;
        height: 100%;
      `
    )}
  }
`;

export default EventsCol;
