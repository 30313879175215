import styled, { css } from 'styled-components/macro';
import LargestCol from 'components/LargestCol';
import { StyledPropertySelect } from '../PropertySelect';
import {
  ENTERTAINMENT_CALENDAR,
  ENTERTAINMENT_CALENDAR_WITH_DETAILS,
} from 'utils/constants';

const CalendarCol = styled(LargestCol)`
  ${({ theme, selectedWeek, hideCalendar, $viewMode }) => css`
    text-align: center;
    padding: 0;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    flex: 0 1 auto;

    ${selectedWeek &&
      css`
        min-height: unset;
      `}

    ${hideCalendar === true &&
      css`
        display: none;
      `}
      
    @media screen and (min-width: ${theme.breakpoints.small}) {
      padding-left: 1em;
      padding-right: 1em;
    }

    @media screen and (min-width: ${theme.breakpoints.large}) {
      ${StyledPropertySelect} {
        display: none;
      }
    }

    /* This breakpoint accounts for padding added to the iframe on property sites */
    /* @media (min-width: calc(${theme.breakpoints.large} - 4rem)) { */
    @media (min-width: ${theme.breakpoints.large}) {
      ${($viewMode === ENTERTAINMENT_CALENDAR ||
        $viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS) &&
        css`
          padding-bottom: 1rem;

          &.col-lg-9 {
            flex: 0 0 70%;
            max-width: 70%;
          }
        `}

      &.col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }

    @media (min-width: ${theme.breakpoints.extraLarge}) {
      ${($viewMode === ENTERTAINMENT_CALENDAR ||
        $viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS) &&
        css`
          padding-bottom: 1rem;

          &.col-lg-9 {
            flex: 0 0 75%;
            max-width: 75%;
          }
        `}

      &.col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
  `}
`;

export default CalendarCol;
