import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { prop, ifProp, ifNotProp } from 'styled-tools';
import moment from 'moment';
import PromotionType from 'components/PromotionType';
import Button from 'components/Button';
import EarnToday from 'routes/HomePage/components/EventCard/EarnToday';
import {
  getDateValues,
  isDrawingDay,
} from 'routes/HomePage/components/EventCard/dateLogic';
import { UPCOMING_EVENTS_WITH_FEATURED } from 'utils/constants';
import { isUpcomingEventsView } from 'utils/viewModeUtils';
import { eventShape } from 'utils/shapes';
import {
  DEFAULT_VIEW_MODE,
  FEATURED_EVENT,
  SPECIAL_EVENT,
} from 'utils/constants';
import ViewDetailsExpand from 'routes/HomePage/components/EventCard/ViewDetailsExpand';
import DefaultImage from 'images/default-event.png';
import DefaultFeaturedImage from 'images/default-event-featured.png';
import DefaultThumbnail from 'images/default-event-thumbnail.png';
import EmployeesOnlyIconImage from 'images/employees-only-icon.png';

const propTypes = {
  event: eventShape,
  earnPeriod: PropTypes.bool,
  selectedDay: PropTypes.object,
  viewMode: PropTypes.string,
  featuredEvent: PropTypes.bool,
  isMobile: PropTypes.bool,
  documentRightEdge: PropTypes.number,
  inMobileApp: PropTypes.bool,
};

const EventDate = styled.time`
  ${({ theme }) => css`
    color: ${theme.colors.darkerGrey};
    font-size: 0.75rem;
    border-right: 1px solid ${theme.colors.borderGrey};
    flex: 1 0 3.125rem;
    max-width: 3.125rem;
    font-weight: 700;
    letter-spacing: 0.14rem;
    padding: 1rem 0.5rem;
    text-transform: uppercase;
    text-align: center;

    strong {
      font-family: 'Hurme Geometric Sans 1';
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      color: ${theme.colors.atmoreRed};
      display: block;
    }

    @media screen and (min-width: ${theme.breakpoints.small}) {
      display: none;
    }

    @media screen and (min-width: ${theme.breakpoints.medium}) {
      display: block;
    }

    @media screen and (min-width: ${theme.breakpoints.large}) {
      display: none;
    }

    ${ifNotProp(
      { viewMode: DEFAULT_VIEW_MODE },
      css`
        display: none;
      `
    )}
  `}
`;

const StyledImage = styled.img`
  width: 100%;

  ${ifProp(
    'featuredEvent',
    css`
      width: 100%;
      flex: 1 0 100%;
      max-width: 100%;
    `
  )}
`;

const ImageLink = styled.a`
  ${({ theme, featuredEvent }) => css`
    align-self: center;
    display: block;
    flex: ${featuredEvent ? '0 1 auto' : '0 1 70%'};
    margin-bottom: auto;
    height: ${featuredEvent ? '' : 'auto'};
    width: 100%;
    max-width: 100%;
    z-index: 1;

    @media screen and (min-width: ${theme.breakpoints.large}) {
      width: ${featuredEvent ? '100%' : '70%'};
      flex-basis: ${featuredEvent ? '100%' : '70%'};
    }
  `}
`;

const EventInfo = styled.div`
  width: 100%;
  overflow-y: hidden;

  ${StyledImage} {
    max-width: 100%;
  }
`;

const EventHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 0.5rem;
  position: relative;

  h1 {
    margin-bottom: 0;
  }
`;

const EventDetails = styled.div`
  padding: 1rem;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 1rem 0.5rem;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    margin-top: ${props => (props.earnPeriod ? '5rem' : '0')};
    padding: 0 1.5rem;

    min-height: 8.875rem;
    max-width: 30%;
    flex: 1 1 30%;
    margin-bottom: 1.876rem;
  }
`;

const StyledEventCard = styled.li`
  ${({ theme, isUpcomingEvents, hide }) => css`
    background-color: ${theme.colors.white};
    display: flex;
    display: ${hide ? 'none' : 'flex'};
    border-top: 1px solid ${theme.colors.borderGrey};
    border-bottom: 1px solid ${theme.colors.borderGrey};

    padding: 0;
    margin: 0;
    list-style: none;

    & + & {
      border-top: 0;
    }

    @media screen and (min-width: ${prop('theme.breakpoints.small')}) {
      ${EventDate} {
        flex: 1 0 10%;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    @media screen and (min-width: ${prop('theme.breakpoints.large')}) {
      border: 0;
      border-radius: 0.25rem;
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
      background-color: ${isUpcomingEvents
        ? 'transparent'
        : theme.colors.white};

      & + & {
        margin-top: 1.5rem;
      }

      ${EventInfo} {
        display: flex;
        flex-wrap: wrap;
        padding: 0 3% 0.625rem;
      }

      ${EventHeader} {
        flex: 0 0 100%;
        padding: 1rem 0;
      }
    }

    ${ifProp(
      'isUpcomingEvents',
      css`
        width: auto;
        max-width: ${props => (props.featuredEvent ? '39.25rem' : '12.125rem')};
        margin: 0;
        flex-direction: column;
        justify-content: flex-start;
        background-color: transparent;
        height: auto;
        border: 0;

        /* At the smallest screen size, we'll only show 2 events,
        *  and allow the Button text to wrap so it fits within the event container */
        flex: 0 1 calc(50% - 0.5rem);
        ${Button} {
          white-space: unset;
        }

        ${ImageLink} {
          width: 100%;
          margin-bottom: 0;
          flex-basis: auto;
        }

        ${ifProp(
          'featuredEvent',
          css`
            width: 100%;
            flex: 1 0 100%;
            max-width: 100%;
          `
        )}

        &&& {
          box-shadow: 0px 0px;
        }

        & + & {
          margin-left: 1rem;
          margin-top: initial;
        }

        ${EventDetails} {
          display: none;
        }

        ${ifNotProp(
          'featuredEvent',
          css`
            .eventTime {
              display: none;
            }
          `
        )}

        ${EventInfo} {
          display: contents;
        }

        .event-details-button {
          margin: 0;
          padding: 0;
          margin-top: 1rem;

          summary {
            width: 100%;
            padding: 0;

            .svg-inline--fa {
              display: none;
            }
          }
        }

        ${ImageLink} {
          order: -2;
        }

        ${StyledImage} {
          flex: 0 1 auto;
          margin-bottom: 0;
        }

        ${EventDate} {
          flex: 0 1 auto;
          max-width: 100%;
          padding: 0;
          margin-top: 0.25rem;
          border-right: 0;
          text-align: left;
          color: ${theme.colors.textGrey};

          strong {
            display: inline;
            font-family: inherit;
            color: inherit;
            font-size: inherit;
          }
        }

        ${EventHeader} {
          flex: 0 1 auto;
          order: -1;
          padding: 0;
          margin-top: 1.25rem;

          h1 {
            font-family: inherit;
            font-size: 1.125rem;
            text-transform: none;
            font-weight: 700;
            letter-spacing: 0;
          }
        }

        ${EventInfo} {
          padding: 0;
        }

        /* At the next breakpoint, show three events. */
        @media screen and (min-width: ${theme.breakpoints.extraSmall}) {
          flex: 0 1 calc(33% - 0.5rem);
        }

        /* At the small breakpoint, return to normal rendering. */
        @media screen and (min-width: ${theme.breakpoints.small}) {
          flex: 0 0 auto;

          ${Button} {
            white-space: nowrap;
          }
        }
      `
    )}
  `}
`;

const PromotionTypeList = styled.ul`
  ${({ theme }) => css`
    list-style: none;
    margin: 0;
    padding: 0;

    @media screen and ${theme.breakpoints.medium} {
      align-self: center;
    }
  `}
`;

const BottomDiv = styled.div`
  width: 100%;

  p {
    margin-bottom: 0.5rem;
  }
`;

const EmployeesOnlyIcon = styled.img`
  ${({ theme }) => css`
    max-width: 3em;
    display: block;
    margin-top: 0.5rem;

    @media screen and ${theme.breakpoints.large} {
      align-self: center;
      margin-top: 0;
    }
  `}
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
`;

class EventCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: false,
    };

    this.eventCardRef = React.createRef();
  }

  componentDidMount() {
    const { documentRightEdge } = this.props;

    // If this card's right edge is beyond the app's right edge, hide it.
    // This setTimeout ensures the card is fully rendered so we get
    // an accurate DOMRect.
    setTimeout(() => {
      const clientRect =
        this.eventCardRef.current &&
        this.eventCardRef.current.getBoundingClientRect();

      const rightEdge = clientRect ? clientRect.x + clientRect.width : 0;

      this.setState({
        hide: rightEdge > documentRightEdge || !this.eventCardRef.current,
      });
    }, 0);
  }

  render() {
    const {
      event,
      earnPeriod = false,
      viewMode,
      featuredEvent,
      isMobile,
      inMobileApp,
    } = this.props;

    const { hide } = this.state;

    const type = event && event.category && event.category.value;
    const eventName = event && event.name ? event.name : null;
    const imageUrl = earnPeriod
      ? event && event.earnPeriodImageUrl
        ? event.earnPeriodImageUrl
        : event.imageUrl
      : event.imageUrl;

    const hasPromotionTypes =
      event && event.promotionTypes && event.promotionTypes.length > 0;
    const instanceDate = moment(event && event.instanceDate);

    const { date, month, day, dayOfWeek } = getDateValues(instanceDate);

    const showDrawingDay = isDrawingDay(
      instanceDate,
      event.startDate,
      event.endDate,
      event.isRecurring,
      event.occurrenceDates
    );

    const hasEarnPeriod = event.earnPeriodStartDate || event.earnPeriodEndDate;

    let imageToShow;
    if (
      viewMode === UPCOMING_EVENTS_WITH_FEATURED ||
      viewMode === FEATURED_EVENT
    ) {
      imageToShow = featuredEvent
        ? event.featuredImageUrl || DefaultFeaturedImage
        : event.thumbnailImageUrl || DefaultThumbnail;
    } else if (isUpcomingEventsView(viewMode)) {
      imageToShow = event.thumbnailImageUrl || DefaultThumbnail;
    }

    // If no featured or thumbnail images, default to the main image.
    imageToShow = imageToShow || imageUrl || DefaultImage;

    return (
      <>
        <StyledEventCard
          type={type}
          earnPeriod={!!event.earnPeriodStartDate || !!event.earnPeriodEndDate}
          isUpcomingEvents={isUpcomingEventsView(viewMode)}
          featuredEvent={featuredEvent}
          ref={this.eventCardRef}
          hide={hide}
        >
          <EventDate datetime={date} viewMode={viewMode}>
            {isUpcomingEventsView(viewMode) ? (
              <>{moment(event.instanceDate).format('dddd, MM/DD/YYYY')}</>
            ) : (
              <>
                {month} <strong>{day}</strong> {dayOfWeek}
              </>
            )}
          </EventDate>
          <EventInfo>
            <EventHeader>
              {eventName && <h1>{eventName}</h1>}
              <HeaderRight>
                {event.isEmployeeOnly && (
                  <EmployeesOnlyIcon
                    src={EmployeesOnlyIconImage}
                    alt='Employees Only'
                  />
                )}
                {!isUpcomingEventsView(viewMode) && (
                  <EarnToday
                    earnPeriod={earnPeriod}
                    isDrawingDay={showDrawingDay && hasEarnPeriod}
                    isInEarnPeriod={instanceDate.isBetween(
                      event.earnPeriodStartDate,
                      event.earnPeriodEndDate,
                      undefined,
                      '[]'
                    )}
                    mobileView
                  />
                )}
              </HeaderRight>
            </EventHeader>
            {imageToShow && (
              <ImageLink
                href={event.detailsUrl}
                target={inMobileApp ? '_self' : '_parent'}
              >
                <StyledImage
                  src={imageToShow}
                  alt={event.imageAlt ? event.imageAlt : eventName}
                  featuredEvent={featuredEvent}
                />
              </ImageLink>
            )}
            <EventDetails
              earnPeriod={
                !!event.earnPeriodStartDate || !!event.earnPeriodEndDate
              }
            >
              {!isUpcomingEventsView(viewMode) && (
                <EarnToday
                  earnPeriod={earnPeriod}
                  isDrawingDay={showDrawingDay && hasEarnPeriod}
                  isInEarnPeriod={instanceDate.isBetween(
                    event.earnPeriodStartDate,
                    event.earnPeriodEndDate,
                    undefined,
                    '[]'
                  )}
                  listView
                />
              )}
              <PromotionTypeList marginTop={!earnPeriod}>
                {hasPromotionTypes &&
                  event.promotionTypes.map((promoType, i) => (
                    <PromotionType
                      type={promoType}
                      key={i}
                      iconOnly={isMobile || event.promotionTypes.length > 1}
                    />
                  ))}
                {type && type === SPECIAL_EVENT && !hasPromotionTypes && (
                  <PromotionType type={SPECIAL_EVENT} iconOnly={isMobile} />
                )}
                {event.promoPerks &&
                  event.promoPerks.map((promoPerk, i) => (
                    <PromotionType
                      key={i}
                      type={promoPerk.value}
                      name={promoPerk.name}
                      isPromoPerk={true}
                    />
                  ))}
                {event.versePerks &&
                  event.versePerks.map((versePerk, i) => (
                    <PromotionType
                      key={i}
                      type={versePerk.value}
                      name={versePerk.name}
                      isVersePerk={true}
                    />
                  ))}
              </PromotionTypeList>
            </EventDetails>
            <BottomDiv>
              {!isUpcomingEventsView(viewMode) && (
                <EarnToday
                  earnPeriod={earnPeriod}
                  isDrawingDay={showDrawingDay && hasEarnPeriod}
                  isInEarnPeriod={instanceDate.isBetween(
                    event.earnPeriodStartDate,
                    event.earnPeriodEndDate,
                    undefined,
                    '[]'
                  )}
                  tabletView
                  marginLeft
                />
              )}
              {isUpcomingEventsView(viewMode) ? (
                <a
                  href={event.detailsUrl ? event.detailsUrl : '#'}
                  target={inMobileApp ? '_self' : '_parent'}
                >
                  View Details
                </a>
              ) : (
                <ViewDetailsExpand
                  event={event}
                  earnPeriod={earnPeriod}
                  inMobileApp={inMobileApp}
                />
              )}
            </BottomDiv>
          </EventInfo>
        </StyledEventCard>
      </>
    );
  }
}

EventCard.propTypes = propTypes;

export default EventCard;
