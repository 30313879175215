import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  gaSetPropertyDimension,
  gaSetSourceDimension,
  sendPageView,
} from 'utils/googleAnalytics';
import { selectProperty } from 'routes/selectors';

const propTypes = {
  children: PropTypes.node,
  trackingId: PropTypes.string,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }),
  property: PropTypes.string,
  themeName: PropTypes.string,
};

function AnalyticsListener({ children, trackingId, history, themeName }) {
  const property = useSelector(selectProperty);

  useEffect(() => {
    if (trackingId) {
      ReactGA.initialize(trackingId);
      gaSetPropertyDimension(property.toLowerCase());
      gaSetSourceDimension(themeName);
      sendPageView(history.location);
      return history.listen(sendPageView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // use empty array for deps so this runs once, only on mount.

  if (!trackingId) {
    return children;
  }

  return children;
}

AnalyticsListener.propTypes = propTypes;

export default withRouter(AnalyticsListener);
