import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import routes from './routes';
import MaintenanceMode from './components/MaintenanceMode';
import { isFixedHeightViewMode } from 'utils/viewModeUtils';

const StyledApp = styled.div.attrs(props => ({
  className: `App theme-${props.theme.name}`,
}))`
  padding: 0;
  overflow-x: hidden;
  height: 100%; // Styles for all themes except WCC

  & > .container-fluid {
    height: 100%;
  }

  ${props =>
    isFixedHeightViewMode(props.viewMode) &&
    css`
      overflow-y: hidden;
    `};
`;

function App() {
  return (
    <StyledApp>
      <Helmet titleTemplate='%s | Wind Creek Promotions Calendar'>
        <title>Promotions Calendar</title>
      </Helmet>
      <Container fluid>
        <MaintenanceMode>
          <Switch>
            {routes}
            <Route render={() => <div>Not Found</div>} />
          </Switch>
        </MaintenanceMode>
      </Container>
    </StyledApp>
  );
}

export default App;
