import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import { addPropertyImagePrefix } from 'utils/images';
import { propertyShape } from 'utils/shapes';
import { fadeInDown, fadeOutUp } from 'react-animations';
import {
  PROPERTY_ARUBA,
  PROPERTY_ATMORE,
  PROPERTY_BETHLEHEM,
  PROPERTY_CURACAO,
  PROPERTY_MONTGOMERY,
  PROPERTY_ONLINE,
  PROPERTY_WASHESHU,
  PROPERTY_WETUMPKA,
} from 'utils/constants';

const StyledPropertyItem = styled.a`
  display: block;
  background: none;
  border: 0;
  border-radius: 0;
  width: 100%;
  text-align: left;
  padding: 1em;
  cursor: pointer;
  color: #ffffff;

  // Using this trick to work around issue with specificity, to avoid using !important.
  // https://www.styled-components.com/docs/advanced#issues-with-specificity
  && {
    text-decoration: none;
  }

  .fa-caret-down {
    margin-left: 0.25em;
    display: none;
  }

  strong {
    display: none;
  }

  &.selected {
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    .fa-caret-down {
      display: inline-block;
    }

    strong {
      display: inline;
    }
  }

  strong,
  h1 {
    color: #fff;
    margin: 0;
  }

  img {
    width: 100%;
  }

  &.${PROPERTY_ATMORE} {
    background: url(${addPropertyImagePrefix('Atmore-select.png')}) top right
      no-repeat;
    background-size: cover;
  }

  &.${PROPERTY_MONTGOMERY} {
    background: url(${addPropertyImagePrefix('Montgomery-select.png')}) top left
      no-repeat;
    background-size: cover;
  }

  &.${PROPERTY_WETUMPKA} {
    background: url(${addPropertyImagePrefix('Wetumpka-select.png')}) top left
      no-repeat;
    background-size: cover;
  }

  &.${PROPERTY_BETHLEHEM} {
    background: url(${addPropertyImagePrefix('Bethlehem-select.png')}) top right
      no-repeat;
    background-size: cover;
  }

  &.${PROPERTY_ARUBA} {
    background: url(${addPropertyImagePrefix('Aruba-select.png')}) top right
      no-repeat;
    background-size: cover;
  }

  &.${PROPERTY_CURACAO} {
    background: url(${addPropertyImagePrefix('Curacao-select.png')}) top right
      no-repeat;
    background-size: cover;
  }

  &.${PROPERTY_WASHESHU} {
    background: url(${addPropertyImagePrefix('WaSheShu-select.png')}) top right
      no-repeat;
    background-size: cover;
  }

  &.${PROPERTY_ONLINE} {
    background: url(${addPropertyImagePrefix('Online-select.png')}) top right
      no-repeat;
    background-size: cover;
  }

  &.open {
    animation: in-animation ${props => props.timeouts}ms;
    display: block;
  }

  &.noopen {
    animation: out-animation ${props => props.timeouts}ms;
    animation-fill-mode: forwards;
  }

  &.hidden {
    display: none;
  }

  @keyframes in-animation {
    ${fadeInDown};
  }

  @keyframes out-animation {
    ${fadeOutUp};
  }
`;

const propTypes = {
  property: propertyShape,
  href: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isOpen: PropTypes.bool,
  timeouts: PropTypes.number,
};

class PropertyItem extends Component {
  handleClick = () => {
    const { property, onClick } = this.props;

    if (onClick) {
      onClick(property.value);
    }
  };

  render() {
    const { href, isSelected, property, isOpen, timeouts } = this.props;

    return (
      <StyledPropertyItem
        className={`property-item ${property.value} 
          ${isSelected ? 'selected' : ''}
          ${isOpen ? 'open' : 'noopen'}
        `}
        href={href}
        target={href ? '_blank' : undefined}
        timeouts={timeouts}
        onClick={this.handleClick}
      >
        <strong>SELECT PROPERTY</strong>
        <h1>
          {property.label}
          <FontAwesomeIcon icon='caret-down' />
        </h1>
      </StyledPropertyItem>
    );
  }
}

PropertyItem.propTypes = propTypes;

export default PropertyItem;
