import { formatMonthYear } from 'react-calendar/src/shared/dateFormatter';

const formatOrdinal = n =>
  [undefined, 'st', 'nd', 'rd'][(n / 10) % 10 ^ 1 && n % 10] || 'th';

export const formatMonthDayYear = (
  locale,
  date,
  calendarOneDay,
  includeOrdinal = false
) => {
  //On the off chance that calendarOneDay somehow isn't set in weekView
  const dateToFormat = calendarOneDay ? calendarOneDay : date;

  //Ignore warning below - toLocaleString does accept 2 args in this context).
  return dateToFormat
    .toLocaleString(locale, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
    .replace(
      ',',
      `${includeOrdinal ? formatOrdinal(dateToFormat.getDate()) : ''},`
    );
};

export const getFormatMonthYear = (locale, date, weekView, calendarOneDay) => {
  return weekView
    ? formatMonthDayYear(locale, date, calendarOneDay)
    : formatMonthYear(locale, date);
};
