import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SanitizeHtml from 'components/SanitizeHtml';
import Button from 'components/Button';
import { eventShape } from 'utils/shapes';
import {
  ENTERTAINMENT_CALENDAR,
  ENTERTAINMENT_CALENDAR_WITH_DETAILS,
} from 'utils/constants';

const EventDetailsButton = styled.details`
  padding-bottom: 0.5rem;
  width: 100%;
  outline: 0;
  align-self: center;

  summary {
    text-align: left;
    padding-left: 1rem;

    span {
      text-decoration: underline;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
      width: calc(30% - 1rem);
    }
  }

  &:focus {
    box-shadow: ${props => props.theme.forms.input.focusOutline};
  }

  .fa-angle-up,
  .details-up {
    display: none;
  }

  .fa-angle-down,
  .fa-angle-up {
    margin-left: 0.5rem;
    margin-top: -0.5rem;
    fill: #a32b29;
  }

  &[open] {
    .fa-angle-up {
      display: inline-block;
    }
    .details-up {
      display: initial;
    }
    .fa-angle-down,
    .details-down {
      display: none;
    }
  }

  & > div {
    text-align: left;
    padding-right: 0.5rem;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 0 0.5rem 0 0;
    text-align: right;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    margin-top: -2.28125rem;
  }

  ${ifProp(
    { $viewMode: ENTERTAINMENT_CALENDAR_WITH_DETAILS },
    css`
      &&& {
        margin: 0;
        padding: 0;
        text-align: left;

        summary {
          padding: 0;

          span {
            text-decoration: underline;
          }
        }
      }
    `
  )}
`;

const ShortDescriptionHeader = styled.h4`
  font-family: 'proxima-nova', Arial, sans-serif;
  font-size: 1.125rem;
  color: ${props => props.theme.colors.atmoreRed};
  margin-bottom: 0.5rem;
`;

const ShortDescription = styled.p`
  font-family: proxima-nova, Arial, sans-serif;
  color: #444444;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.125rem;
  clear: left;
  white-space: pre-wrap;
`;

const HowToQualifyHeader = styled(ShortDescriptionHeader)`
  margin-top: 1rem;
  font-size: 1rem;
`;

const StyledDiv = styled.div`
  ${({ theme, $viewMode }) => css`
    margin-top: 1rem;
    padding-left: 1rem;

    @media screen and (min-width: ${theme.breakpoints.large}) {
      padding-left: 0;
    }

    @media screen and (min-width: ${theme.breakpoints
        .large}) and (max-width: 995px) {
      margin-top: 0.5rem;
      width: 40vw;
    }

    ${($viewMode === ENTERTAINMENT_CALENDAR ||
      $viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS) &&
      css`
        @media screen and (min-width: ${theme.breakpoints
            .large}) and (max-width: 995px) {
          margin-top: 0.5rem;
          width: auto;
        }
      `}
  `}
`;

const EventTimeDetails = styled.div`
  flex: 0 1 auto;
  margin-top: auto;
  white-space: pre-wrap;

  p:not(:last-of-type) {
    margin-bottom: 0;
  }
`;

function ViewDetailsExpand({ event, earnPeriod, inMobileApp, viewMode = '' }) {
  const {
    name,
    shortDescription,
    shortDescriptionHeader,
    howToQualifyHeader,
  } = { ...event };
  const showDetails =
    viewMode && viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS;
  const isEntertainmentCalendar =
    viewMode &&
    (viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS ||
      viewMode === ENTERTAINMENT_CALENDAR);

  return (
    <EventDetailsButton className={'event-details-button'} $viewMode={viewMode}>
      <summary>
        <span className={'details-up'}>Hide Details</span>
        <span className={'details-down'}>View Details</span>
        <FontAwesomeIcon
          icon='angle-up'
          aria-hidden='true'
          aria-label='angle-up'
        />
        <FontAwesomeIcon
          icon='angle-down'
          aria-hidden='true'
          aria-label='angle-down'
        />
      </summary>
      <StyledDiv $viewMode={viewMode}>
        {shortDescriptionHeader && !showDetails && (
          <ShortDescriptionHeader>
            {shortDescriptionHeader}
          </ShortDescriptionHeader>
        )}
        {shortDescription && (
          <ShortDescription>{shortDescription}</ShortDescription>
        )}
        {howToQualifyHeader && (
          <HowToQualifyHeader>{howToQualifyHeader}</HowToQualifyHeader>
        )}
        <EventTimeDetails className={'eventTime'}>
          {event && event.occurrenceDetails && !earnPeriod && (
            <p>
              <SanitizeHtml html={event.occurrenceDetails} />
            </p>
          )}
          {event && event.earnPeriodDescription && earnPeriod && (
            <p>
              <SanitizeHtml html={event.earnPeriodDescription} />
            </p>
          )}
        </EventTimeDetails>
        {event && event.detailsUrl && !isEntertainmentCalendar && (
          <Button
            as='a'
            href={event.detailsUrl}
            className='button text'
            aria-label={name + ' See Complete Details'}
            target={inMobileApp ? '_self' : '_parent'}
          >
            See Complete Details
          </Button>
        )}
      </StyledDiv>
    </EventDetailsButton>
  );
}

ViewDetailsExpand.propTypes = {
  event: eventShape,
  earnPeriod: PropTypes.bool,
  inMobileApp: PropTypes.bool,
  viewMode: PropTypes.string,
};

export default ViewDetailsExpand;
