/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our application state.
 * To add a new action, add it to the ACTION_HANDLERS object.
 */

import { combineReducers } from 'redux';
import { getDefaultPropertyForTheme } from 'utils/themeUtils';
import calendarReducer from './HomePage/reducer';

const defaultProperty = getDefaultPropertyForTheme();

// The initial state of the App
const initialState = {
  token: '',
  property: defaultProperty,
};

// Actions
const SET_TOKEN = 'app/SET_TOKEN';
const SET_PROPERTY = 'app/SET_PROPERTY';

// Action Handlers
const ACTION_HANDLERS = {
  [SET_TOKEN]: (state, { payload }) =>
    Object.assign({}, state, {
      token: payload.token,
    }),
  [SET_PROPERTY]: (state, action) =>
    Object.assign({}, state, {
      property: action.payload.property,
    }),
};

// Action Creators
export function setToken(token) {
  return {
    type: SET_TOKEN,
    payload: { token },
  };
}

export function setProperty(property) {
  return {
    type: SET_PROPERTY,
    payload: { property },
  };
}

// Reducer
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

// Combine the global reducer with the route reducers here.
export default combineReducers({
  global: reducer,
  calendar: calendarReducer,
});
