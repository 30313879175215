import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import PromotionIcon from 'components/PromotionIcon';
import { eventShape } from 'utils/shapes';
import {
  ENTERTAINMENT_CALENDAR,
  ENTERTAINMENT_CALENDAR_WITH_DETAILS,
} from 'utils/constants';

const MAX_LENGTH_TITLE = 32;
const ELLIPSIS = '... ';

const EventName = styled.h5`
  margin-bottom: 0;
  color: ${props => props.theme.colors.textGrey};
  text-transform: uppercase;
  font-size: 0.6em;
`;

const EventTime = styled.h5`
  text-transform: uppercase;
  font-size: 0.6em;
`;

const EventCount = styled.h5`
  text-transform: uppercase;
  font-size: 0.6em;
  &&& {
    color: ${props => props.theme.colors.textGrey};
  }
`;

const DayTileContent = styled.div`
  ${EventName},
  ${EventTime},
  ${EventCount} {
    display: none;
  }

  .test > div {
    font-size: 0.6em;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
    ${props =>
      (props.viewMode === ENTERTAINMENT_CALENDAR ||
        props.viewMode === ENTERTAINMENT_CALENDAR_WITH_DETAILS) &&
      css`
        ${EventName},
        ${EventTime},
        ${EventCount} {
          display: block;
        }

        ${PromotionIcon} {
          display: none;
        }
      `}}
  }
`;

const propTypes = {
  isSelected: PropTypes.bool,
  hasEvents: PropTypes.bool,
  events: PropTypes.arrayOf(eventShape),
  viewMode: PropTypes.string,
};

function getTruncatedTitle(title) {
  if (title.length > MAX_LENGTH_TITLE) {
    const truncatedTitle = title.substr(0, MAX_LENGTH_TITLE);

    // Find the last space in the string. If we can fit the ellipsis into the string
    // after this space without exceeding MAX_TITLE_LENGTH, truncate the string at
    // this space. Otherwise, go to the next space.
    const lastSpace = truncatedTitle.lastIndexOf(' ');
    const truncatePosition =
      lastSpace + ELLIPSIS.length < MAX_LENGTH_TITLE - 1
        ? lastSpace
        : truncatedTitle.lastIndexOf(' ', lastSpace);

    return `${truncatedTitle.substr(0, truncatePosition)}${ELLIPSIS}`;
  } else {
    return title;
  }
}

function DayTile({ hasEvents, events, viewMode }) {
  return (
    <DayTileContent viewMode={viewMode} events={events}>
      {hasEvents && events.length > 0 && (
        <>
          <EventName className='event-name'>
            {getTruncatedTitle(events[0].name)}
          </EventName>
          <EventTime>{events[0].shortDescriptionHeader}</EventTime>
          {events.length > 1 && (
            <EventCount className='event-count'>
              +{events.length - 1} More!
            </EventCount>
          )}
          <PromotionIcon icon='circle' aria-label='day-has-events' />
        </>
      )}
    </DayTileContent>
  );
}

DayTile.propTypes = propTypes;

export default DayTile;
