import Cookies from 'universal-cookie';

const MAINTENANCE_COOKIE = 'en4_maint_code';

const OPTIONS = {
  path: '/',
  domain: process.env.REACT_APP_COOKIE_DOMAIN,
  secure: process.env.REACT_APP_SECURE_COOKIE === 'true',
  maxAge: process.env.REACT_APP_COOKIE_MAX_AGE || 2592000, // Defaults maxAge to 1 month.
};

const MAINTENANCE_OPTIONS = {
  ...OPTIONS,
  maxAge: 24 * 60 * 60,
};

const cookies = new Cookies();

export function getMaintenanceCookie(code) {
  return cookies.get(MAINTENANCE_COOKIE);
}

export function getCredentialsSetting() {
  // When in maintenance mode, send cookie so requests are accepted
  return getMaintenanceCookie() ? 'same-origin' : 'omit';
}

export function setMaintenanceCookie(code) {
  cookies.set(MAINTENANCE_COOKIE, code, MAINTENANCE_OPTIONS);
}

export function clearMaintenanceCookie() {
  cookies.remove(MAINTENANCE_COOKIE, MAINTENANCE_OPTIONS);
}
