import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from 'components/CloseIcon';
import FilterIcon from 'components/FilterIcon';
import Button from 'components/Button';
import { EVENT_TYPE_DISPLAY_NAMES, EVENT_TYPES } from 'utils/constants';
import useEvent from 'utils/useEvent';

const FilterButton = styled(Button)`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  padding-top: 0;
  position: relative;
  align-items: center;
  cursor: pointer;
  transition: none;

  .icon--caret {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.8rem;
    vertical-align: middle;
    display: inline-block;

    &&& {
      font-size: 0.75rem;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    &&& {
      width: 12.3125rem;

      .icon--caret {
        margin-left: auto;
      }
    }
  }
`;

const FilterDetails = styled.div`
  border-radius: 0.25rem;
  position: absolute;
  right: 0rem;
  top: 0rem;
  display: ${props => (props.show ? 'initial' : 'none')};
  ${props =>
    !props.isMobile &&
    css`
      width: 100%;
      max-width: 18.75rem;
      top: 0.625rem;
      right: 1rem;
    `}

  &[open] {
    background-color: ${props => props.theme.buttons.trigger.backgroundColor};
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
    padding: 0 0.5rem 0.5rem;
    z-index: 1;

    ${FilterButton} {
      margin-bottom: 1rem;
      transform: translateX(0.5rem);

      .icon--caret {
        transform: rotate(180deg);
      }
    }
  }
`;

const CloseButton = styled(Button)`
  &&& {
    text-decoration: none;
    color: ${props => props.theme.colors.darkerGrey};
    font-family: 'proxima-nova';
    font-size: 0.75rem;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    transform: translateY(-0.125rem);
    vertical-align: middle;
    fill: ${props => props.theme.buttons.text.color};
    display: inline-block;
    max-width: 100%;
    margin-right: 0.5rem;
  }
`;

const FilterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 1rem;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: 0.5rem;

    &&& {
      button {
        font-size: 1rem;
        padding: 0.25rem 0;
      }
    }
  }
`;

const EventTypeList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0;
    text-align: left;
  }

  /* The container */
  .container {
    color: ${props => props.theme.colors.darkerGrey};
    display: block;
    font-size: 1.125rem;
    color: ${props => props.theme.colors.darkerGrey};
    letter-spacing: 0;
    font-weight: 500;
    text-transform: none;
    margin-bottom: 0;
    font-family: 'proxima-nova';
    position: relative;
    padding-left: 2.188rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
    user-select: none;
    margin-left: 0.75rem;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    background-color: ${props => props.theme.colors.grey};
    border: 2px solid ${props => props.theme.colors.atmoreRed};
    border-radius: 0.125rem;
  }

  &&& {
    input:focus ~ .checkmark {
      background-color: ${props => props.theme.colors.textGrey};
    }
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: ${props => props.theme.colors.textGrey};
  }

  /* When the checkbox is checked, add red background */
  .container input:checked ~ .checkmark {
    background-color: ${props => props.theme.colors.atmoreRed};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    bottom: 0.1rem;
    left: 0.25rem;
    width: 0.5rem;
    height: 1rem;
    border-bottom: 3px solid ${props => props.theme.colors.white};
    border-right: 3px solid ${props => props.theme.colors.white};
    transform: rotate(45deg);
  }
`;

const StyledSpan = styled.span`
  @media screen and (max-width: 350px) {
    font-size: 1.35rem;
  }
`;

function EventFilter({ setTypeFilter, typeFilter, show, isMobile, id }) {
  const [selectedFilters, setSelectedFilters] = useState(typeFilter);
  const [isOpen, setIsOpen] = useState(false);

  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.target.checked = !e.target.checked;
      handleFilterChange(e);
    }
  };

  const handleFilterChange = e => {
    setSelectedFilters(
      e.target.checked
        ? [...selectedFilters, e.target.value]
        : selectedFilters.filter(eventType => eventType !== e.target.value)
    );
  };

  const handleClearClick = e => {
    e.preventDefault();
    setSelectedFilters([]);
    setTypeFilter([]);
  };

  const handleApplyClick = e => {
    e.preventDefault();
    setTypeFilter(selectedFilters);
  };

  const getId = () => 'event-filters' + (id ? `-${id}` : '');

  useEvent('click', function(e) {
    const id = getId();
    if (
      isOpen &&
      e.target instanceof HTMLElement &&
      document.getElementById(id) !== null &&
      !document.getElementById(id).contains(e.target)
    ) {
      setIsOpen(!isOpen);
    }
  });

  return (
    <FilterDetails id={getId()} open={isOpen} show={show} isMobile={isMobile}>
      <FilterButton className='trigger' onClick={() => setIsOpen(!isOpen)}>
        <FilterIcon id={id} />
        <StyledSpan>Filter By</StyledSpan>
        <FontAwesomeIcon
          icon='chevron-down'
          className='icon--caret'
          aria-label='chevron'
        />
      </FilterButton>
      {isOpen && (
        <div>
          <form>
            <EventTypeList>
              {EVENT_TYPES.map((eventType, i) => (
                <li key={i}>
                  <div>
                    <label className={'container'}>
                      <input
                        type='checkbox'
                        value={eventType}
                        onChange={handleFilterChange}
                        checked={selectedFilters.includes(eventType)}
                        onKeyPress={handleKeyDown}
                        role={'group'}
                        aria-label={EVENT_TYPE_DISPLAY_NAMES[eventType]}
                      />
                      <span className='checkmark' />
                      {EVENT_TYPE_DISPLAY_NAMES[eventType]}
                    </label>
                  </div>
                </li>
              ))}
            </EventTypeList>
            <FilterButtons>
              <CloseButton
                className='text icon'
                onClick={handleClearClick}
                disabled={typeFilter.length === 0}
              >
                <CloseIcon />
                Clear
              </CloseButton>
              <Button className='text' onClick={handleApplyClick}>
                Apply Changes
              </Button>
            </FilterButtons>
          </form>
        </div>
      )}
    </FilterDetails>
  );
}

EventFilter.propTypes = {
  setTypeFilter: PropTypes.func.isRequired,
  typeFilter: PropTypes.array.isRequired,
};

export default EventFilter;
