import { combineReducers } from 'redux';
import appReducer from './routes/reducer';

export const RESET_APP = 'RESET_APP';

export function resetApp() {
  return {
    type: RESET_APP,
  };
}

const allReducers = combineReducers({
  app: appReducer,
});

function rootReducer(state, action) {
  if (action.type === RESET_APP) {
    state = undefined;
  }

  return allReducers(state, action);
}

export default rootReducer;
